import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import Background from '../../common/images/side-bg.jpg';
import loginImg from "../../common/images/energy-logo-black.png"
class MenuSidebar extends Component {
	
	constructor(props)
	{
		super(props);
		this.state = {
			  currentpage:this.props.currentpage,
			  admin_id:localStorage.getItem('admin_id'),
			  admin_company_name : localStorage.getItem("admin_company_name"),
			  module_type : localStorage.getItem("module_type"),
		};
		
    }
	
	activeMenu(currentPage) { 
	// console.log(this.state.currentpage,'cutentpage');
        let status = '';
            if(this.state.currentpage===currentPage) {
                status = 'nav-item has-sub active'
            }else{
            status = 'nav-item has-sub enable';
        }
        return status;
    }
	
  render() {
    return (	
	<div className="sidebar-menu mobi-close">
	   <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow expanded" data-scroll-to-active="true" >
  <div className="navbar-header expanded">
    <ul className="nav navbar-nav flex-row position-relative">
      <li className="nav-item mr-auto">
        <a className="navbar-brand" href="#">
        {/* <img src={loginImg} /> */}
			{this.state.admin_company_name}
		
        </a>
      </li>
      <li className="nav-item d-md-none"><a href="javascript:void(0)" className="nav-link close-navbar mb-side-nav"><i className="ft-x"></i></a></li>
    </ul>
  </div>
  <div className="navigation-background" style={{background: `url(${Background})`}}></div>
  <div className="main-menu-content ps ps--active-y" style={{height: "100%"}}>
  <Scrollbars className="custom-scroll-wrap">
    <ul className="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
      <li className={this.activeMenu('dashboard')}>
        <a href="/dashboard">
          <i className="ft-home"></i>
          <span className="menu-title" data-i18n="">Dashboard</span>
        </a>
      </li>

      <li className={this.activeMenu('user')}>
        <a href="/user">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Users</span>
        </a>
      </li>
	  
	  {/* <li className={this.activeMenu('brokerlist')}>
        <a href="/broker">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Brokers</span>
        </a>
      </li> */}

{this.state.module_type =='withlenders' ?<li className={this.activeMenu('lenderlist')}>
        <a href="/lender">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Lenders</span>
        </a>
      </li>:null}
      {this.state.module_type =='withlenders' ?<li className={this.activeMenu('investors')}>
        <a href="/investors">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Investors</span>
        </a>
      </li>:null}

      {this.state.admin_id == 1 && this.state.module_type =='withlenders' ?<li className={this.activeMenu('companylist')}>
        <a href="/company">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Companies</span>
        </a>
      </li>:""}
     <li className={this.activeMenu('leads')}>
        <a href="/leads">
          <i className="ft-users"></i>
          <span className="menu-title" data-i18n="">Leads</span>
        </a>
      </li>

      {/* <li className={this.activeMenu('Category')}>
					<a href="/category">
						<i className="ft-server" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Category</span>
					</a>
				</li> */}

{this.state.module_type =='withlenders' ? <li className={this.activeMenu('transactionhistory')}>
        <a href="/transactionhistory">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Transaction History</span>
        </a>
      </li>:null}

      {this.state.module_type =='withlenders' ? <li className={this.activeMenu('tabmanagement')}>
        <a href="/tabmanagement">
          <i className="ft-tab-management"></i>
          <span className="menu-title" data-i18n="">Tab Management</span>
        </a>
      </li>:null}
      {this.state.module_type =='withlenders' ? <li className={this.activeMenu('flags')}>
        <a href="/flags">
          <i className="ft-tab-management"></i>
          <span className="menu-title" data-i18n="">Flags</span>
        </a>
      </li>:null}
      
	   {this.state.module_type =='withlenders' ? <li className={this.activeMenu('dashboardmanagement')}>
        <a title="Dashboard Management Builder" href="/dashboardmanagement">
          <i className="ft-server"></i>
          <span className="menu-title" data-i18n="" >Dashboard Management</span>
        </a>
      </li>:null}

      {/* {this.state.module_type =='withlenders' ?<li className={this.activeMenu('loantypes')}>
        <a href="/loantypes">
          <i className="ft-business-type"></i>
          <span className="menu-title" data-i18n="">Loan Types</span>
        </a>
      </li>:null} */}
      {this.state.module_type =='withlenders' ?<li className={this.activeMenu('loantype')}>
        <a href="/loantype">
          <i className="ft-loan-config"></i>
          <span className="menu-title" data-i18n="">Loan Types</span>
        </a>
      </li>:null}
      {this.state.module_type =='withlenders' ?<li className={this.activeMenu('loanconfig')}>
        <a href="/loanconfiguration">
          <i className="ft-loan-config"></i>
          <span className="menu-title" data-i18n="">Loan Configuration</span>
        </a>
      </li>:null}
      {this.state.module_type =='withlenders' ?<li className={this.activeMenu('annualconfiguration')}>
        <a href="/annualconfiguration">
          <i className="ft-loan-config"></i>
          <span className="menu-title" data-i18n="">Rating Configuration</span>
        </a>
      </li>:null}
      {this.state.module_type =='withlenders' ?<li className={this.activeMenu('ratingCalculation')}>
        <a href="/ratingCalculation">
          <i className="ft-loan-config"></i>
          <span className="menu-title" data-i18n="">Rating Calculation</span>
        </a>
      </li>:null}
      {this.state.module_type =='withlenders' ?<li className={this.activeMenu('businesscategories')}>
        <a href="/businesscategories">
          <i className="ft-business-category"></i>
          <span className="menu-title" data-i18n="">Business Categories</span>
        </a>
      </li>:null}

      {this.state.module_type =='withlenders' ? <li className={this.activeMenu('businesstypes')}>
        <a href="/businesstypes">
          <i className="ft-business-type"></i>
          <span className="menu-title" data-i18n="">Business Types</span>
        </a>
      </li>:null}

      {this.state.module_type =='withlenders' ? <li className={this.activeMenu('MembershipCategory')}>
        <a href="/Membershipcategory">
          <i className="ft-memb-category" aria-hidden="true"></i>
          <span className="menu-title" data-i18n="">Membership Categories</span>
        </a>
      </li>:null}

{this.state.module_type =='withlenders' ?<li className={this.activeMenu('membership')}>
        <a href="/membership">
          <i className="ft-membership"></i>
          <span className="menu-title" data-i18n="">Memberships</span>
        </a>
      </li>:null}

     <li className={this.activeMenu('FaqCategory')}>
					<a href="/Faqcategory">
						<i className="ft-faq-category" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Faq Categories</span>
					</a>
				</li>

        <li className={this.activeMenu('Faq')}>
					<a href="/Faq">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Faq</span>
					</a>
				</li>

        <li className={this.activeMenu('AdvertisementCategory')}>
					<a href="/Advertisementcategory">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Advertisement Categories</span>
					</a>
				</li>

        <li className={this.activeMenu('advertisement')}>
        <a title="Advertisement" href="/advertisement">
          <i className="ft-faq"></i>
          <span className="menu-title" data-i18n="">Advertisement</span>
        </a>
      </li>
      
      <li className={this.activeMenu('GuideCategory')}>
					<a href="/GuideCategory">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Guide Categories</span>
					</a>
				</li>
      <li className={this.activeMenu('GuideSubCategory')}>
					<a href="/GuideSubCategory">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Guide Sub Categories</span>
					</a>
				</li>
       <li className={this.activeMenu('guide')}>
          <a href="/guide">
            <i className="ft-tab-management"></i>
            <span className="menu-title" data-i18n="">Guide</span>
          </a>
        </li>
        <li className={this.activeMenu('bookkeepingcategory')}>
          <a href="/bookkeepingcategory">
            <i className="ft-tab-management"></i>
            <span className="menu-title" data-i18n="">Bookkeeping Categories</span>
          </a>
        </li>
       <li className={this.activeMenu('Aifeed')}>
					<a href="/aifeed">
						<i className="ft-faq" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Manage AI Feed</span>
					</a>
				</li>

        {/* <li className={this.activeMenu('Brokersplitpayment')}>
					<a href="/Brokersplitpayment">
						<i className="ft-broker-payment" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Broker Split Payment</span>
					</a>
				</li> */}

{this.state.module_type =='withlenders' ?  <li className={this.activeMenu('invitefriend')}>
        <a href="/invitefriend">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Invite Friend</span>
        </a>
      </li>:null}

      <li className={this.activeMenu('fonts')}>
        <a href="/fonts">
          <i className="ft-type"></i>
          <span className="menu-title" data-i18n="">Fonts</span>
        </a>
      </li>
	  
	   <li className={this.activeMenu('rolemanagement')}>
        <a href="/rolemanagement">
          <i className="ft-type"></i>
          <span className="menu-title" data-i18n="">Role Management</span>
        </a>
      </li>
	  
	  <li className={this.activeMenu('userrolemanagement')}>
		<a href="/userrolemanagement">
		<i className="ft-server" aria-hidden="true"></i>
		<span className="menu-title" data-i18n="">Team Members</span>
		</a>
		</li>
	  
      <li className={this.activeMenu('formbuilder')}>
					<a title="Form Builder" href="/formbuilder">
						<i className="ft-formbuilder" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Form Builders</span>
					</a>
				</li>
      {this.state.admin_id == 1 ?<li className={this.activeMenu('templates')}>
        <a href="/templates">
          <i className="ft-feather"></i>
          <span className="menu-title" data-i18n="">Templates</span>
        </a>
        </li>:''} 
        
      <li className={this.activeMenu('plugincontent')}>
					<a href="/plugincontent">
						<i className="ft-edit" aria-hidden="true"></i>
						<span className="menu-title" data-i18n="">Plugin Contents</span>
					</a>
				</li>

	  <li className={this.activeMenu('setting')}>
        <a href="/setting">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Settings</span>
        </a>
      </li>

      
      
      {/* <li className={this.activeMenu('annualrevenue')}>
        <a href="/annualrevenue">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Annual Revenue</span>
        </a>
      </li> */}

      {/* <li className={this.activeMenu('businesstime')}>
        <a href="/businesstime">
          <i className="ft-settings"></i>
          <span className="menu-title" data-i18n="">Time in Business</span>
        </a>
      </li> */}

      
    </ul>
    </Scrollbars>
  </div>
</div>
	</div>		

	
    );
  }
}

export default MenuSidebar;