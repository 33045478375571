import React, { Component } from 'react';
//import { useParams, useNavigate } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { carImageUrl } from '../Config/Config';
import { apiUrl} from'../Config/Config';
import axios from 'axios';
//import { CKEditor, CKEditorContext } from '@ckeditor/ckeditor5-react';
//import MyEditor from '../Membership/MyEditor';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
/* 
import {GET_EDITUSER,GET_USERDETAILLIST  }  from '../../actions';  */
// const withRouter = WrappedComponent => props => {
// 	const params = useParams();
// 	const  navigate = useNavigate();
// 	// etc... other react-router-dom v6 hooks
  
// 	return (
// 	  <WrappedComponent
// 		{...props}
// 		params={params}
// 		navigate ={navigate}
// 		// etc...
// 	  />
// 	);
//   };
class Edit extends Component {
	
	fileObj = [];
    fileArray = [];
    imageArray = [];
	
	constructor(props)
	{
		super(props);	
	   // const formId = (typeof props.params.ID === 'undefined') ? '' : props.params.ID;
		const formId = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

		this.state = {
			form_id : formId,
	      form_name:'',
	      form_one_column:true,
	      form_two_column:false,
	      form_field_name:'',
	      form_input_type:'',
	      form_input_type_value:'',
	      field_priorty:'',
	      form_field_name_arr:[],
	      form_input_type_arr:[],
	      form_input_type_value_arr:[],
	      form_input_column_value_arr:[],
	      field_priorty_arr:[],
	      field_required_checked:true,
	      field_required:'',
	      field_required_arr:[],
	      showlayout: false,
	      select_options:'',
	      select_options_arr:[],
	      selectedvalue: '',
          selectedOption:{ value: 'active', label: 'Active'},
		  selectedTableOption:{ value: 'fund_form', label: 'Fund Form'},
			selectedTableOptionValue:'',
			selectedColumnOption:{ value: 'firstname', label: 'Firstname' },
			selectedColumnOptionValue:'',
			selectedDynamicDropOption:{ value: '', label: '' },
			selectedDynamicDropOptionValue:'',
			selectedDynamic_arr:[],
          Loading:false,
          editindex:'',
          field_ids:[],
		  remove_ids:''
		};
		this.deletesinglefield = this.deletesinglefield.bind(this);
		this.onEditorChange = this.onEditorChange.bind(this);
		var qs = require('qs');
		axios.get(apiUrl+"formbuilder/formdetail?id="+formId).then(res => {
			if(res.data.status == 'success'){
				const formdata = res.data.formdata;

				this.setState({form_name : formdata.formname});
						if(formdata.formcolumn == 1){
							this.setState({form_one_column: true,form_two_column: false});
						}else{
							this.setState({form_two_column: true,form_one_column:false})
						}
						if(formdata.formstatus === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
						}
					
					  	if(formdata.field){
					  		this.setState({form_field_name_arr: formdata.field,showlayout:true});
					  	}
					  	if(formdata.priority){
					  		this.setState({field_priorty_arr: formdata.priority});
					  	}
						  
					  	if(formdata.required){
					  		this.setState({field_required_arr : formdata.required});
					  	}
					  	if(formdata.fieldtype){
					  		this.setState({form_input_type_value_arr: formdata.fieldtype});
					  	}
						  if(formdata.fieldcolumn){
							this.setState({form_input_column_value_arr: formdata.fieldcolumn});
						}

						  
					  	if(formdata.field_id){
					  		this.setState({field_ids: formdata.field_id});
					  	}
					  	if(formdata.options){
					  		this.setState({select_options_arr: formdata.options});
					  	}
						  if(formdata.dynamic_field){
							this.setState({selectedDynamic_arr: formdata.dynamic_field});
						}
						if(formdata.status === 'A'){
							this.setState({selectedOption:{value: 'active', label: 'Active'}});
						}else{
							this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});

						}
						this.setState({type:formdata.type});
						this.setState({price:formdata.price});
						var callfunc = this;
						setTimeout(function () {
						callfunc.setState({package_content:formdata.package_content});
						}, 1000);

						//this.setState({logo_content:formdata.landing_content});
						this.setState({package_id: formdata.id});
			}
			
		}); 
		
	   this.handleInputChange = this.handleInputChange.bind(this);
	   this.new_pass_show_hide = this.new_pass_show_hide.bind(this);
       this.confim_pass_show_hide = this.confim_pass_show_hide.bind(this);
	   this.handleChange = this.handleChange.bind(this);
	   
    }
	isUSZipCode(zipCode) {
		// Regular expression pattern for validating US ZIP codes
		const zipCodePattern = /^\d{5}(-\d{4})?$/;
		return zipCodePattern.test(zipCode);
	  }
	  handleInputChange = (event) =>{
		const { name,value } = event.target;
		this.setState({[name]: value})
		 if(name === 'form_column'){
		   this.setState({form_one_column: !this.state.form_one_column});
		   this.setState({form_two_column: !this.state.form_two_column});
		}
		if(name === 'field_required'){
			this.setState({field_required_checked: !this.state.field_required_checked});
		}
		if(name == 'select_options'){
			this.setState({select_options:value})
		}
	  }
	
     handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };

	handleChangeCommon = (selectedOption, { name }) => {
		this.setState({
			[name]: selectedOption,  
			[`${name}Value`]: selectedOption.value 
		});
		console.log(name,'-name')
		console.log(`${name}Value`,'-value')
		console.log(selectedOption.value,'-selectedOption.value')
	};

	componentDidMount() {
		document.title = PageTitle('user Edit');
		if(localStorage.getItem('admin_id') === null){
		this.props.history.push('/');
		}
		
    }
	onEditorChange( evt ) {
    	console.log(evt.editor.getData())
    	var callfunc = this;
    	setTimeout(function () {
		callfunc.setState( {
            package_content: evt.editor.getData()
        } );
		}, 1000);
     
    }
	new_pass_show_hide = () => {
    this.setState({
         new_pass_type: this.state.new_pass_type === 'input' ? 'password' : 'input',
         pass_show_hide_icon: this.state.new_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
    
    confim_pass_show_hide = () => {
    this.setState({
         confrim_pass_type: this.state.confrim_pass_type === 'input' ? 'password' : 'input',
         confirm_show_hide_icon: this.state.confrim_pass_type === 'input' ? 'shown' : 'hiden'
        });
    }
	deletesinglefield(Index, id){
		let fieldname = [...this.state.form_field_name_arr];
		let fieldtype = [...this.state.form_input_type_value_arr];
		let fieldcolumn = [...this.state.form_input_column_value_arr];
		let fieldrequired = [...this.state.field_required_arr];
		let fieldpriorty = [...this.state.field_priorty_arr]; 
		let selectoptarr = [...this.state.select_options_arr]; 
		let selectdyrarr = [...this.state.selectedDynamic_arr]; 
		let fieldids = [...this.state.field_ids]; 
		fieldname.splice(Index, 1);
		fieldtype.splice(Index, 1);
		fieldcolumn.splice(Index, 1);
		fieldrequired.splice(Index, 1);
		fieldpriorty.splice(Index, 1);
		selectoptarr.splice(Index, 1);
		selectdyrarr.splice(Index, 1);
		fieldids.splice(Index, 1);
		this.setState({form_field_name_arr: fieldname,form_input_type_value_arr: fieldtype,field_required_arr:fieldrequired,field_priorty_arr:fieldpriorty,select_options_arr:selectoptarr,field_ids:fieldids,form_input_column_value_arr: fieldcolumn,selectedDynamic_arr:selectdyrarr});
		this.setState(prevState => ({
		  remove_ids: [...prevState.remove_ids, id]
		}))
	}
	editsinglefield = (index) => {

		this.setState({form_field_name:this.state.form_field_name_arr[index],field_priorty:this.state.field_priorty_arr[index],editindex: index});

		var fieldtype = this.state.form_input_type_value_arr[index];

		let fieltypeval = '';
			if (fieldtype && fieldtype.length > 0) {
				fieltypeval = fieldtype.charAt(0).toUpperCase() + fieldtype.slice(1);
			}
		
		this.setState({form_input_type: {value: fieldtype, label: fieltypeval},form_input_type_value: fieldtype});

		//////////////
		var fieldColtype = this.state.form_input_column_value_arr[index];

		//let fieldColtypeval = '';
			// if (fieldtype && fieldtype.length > 0) {
			// 	fieldColtypeval = fieldtype.charAt(0).toUpperCase() + fieldtype.slice(1);
			// }
		
		this.setState({selectedColumnOption: {value: fieldColtype, label: fieldColtype},selectedColumnOptionValue: fieldColtype});

		/////////////

		if(this.state.field_required_arr[index] == 'yes'){
			this.setState({field_required_checked : true})
		}else{
			this.setState({field_required_checked : false})
		}
		if(this.state.select_options_arr[index]!==''){
			this.setState({select_options:this.state.select_options_arr[index]});
		}
		if(this.state.selectedDynamic_arr[index]!==''){
			var dycol = this.state.selectedDynamic_arr[index];
	

			const matchingOption = lang.common.frontend_default_lable_option.find(option => option.value === dycol);

			if (matchingOption) {
				this.setState({
					selectedDynamicDropOption: {
						value: dycol, 
						label: matchingOption.label
					},
					selectedDynamicDropOptionValue: matchingOption.value 
				});
			} else {
				// Handle case where no match is found (optional)
				this.setState({
					selectedDynamicDropOption: { value: dycol, label: 'Unknown' }
				});
			}
		}

    }
 

    
	  handledomainChange = selectedDomainOption => {
		
		this.setState({selectedDomainOption:selectedDomainOption})
		this.setState({  selectedDomainvalue : selectedDomainOption.value});
	
	}

handleYearChange = selOption => {
	this.setState({selectedYear:selOption})
		this.setState({  selectedYearvalue : selOption.value});
};
handleProductCategoryChange = (event) => {  
	console.log(event,'onchange')
	this.setState({selectedamenityid : event}, function() {
	   const user_list = this.state.selectedamenityid;
	});
}
getformlayout() {
    // Ensure form_field_value is either an array or is safely converted to an array
    var form_field_value = this.state.form_field_name_arr;

    // If form_field_value is not an array, handle it appropriately
    if (!Array.isArray(form_field_value)) {
        // If it's an object with keys, you might want to convert it to an array or handle it differently
        if (form_field_value && typeof form_field_value === 'object') {
            form_field_value = Object.values(form_field_value);
        } else {
            // If it's something else (e.g., null, string, number), default to an empty array
            form_field_value = [];
        }
    }

    if (form_field_value.length > 0) {
        const formlayout = form_field_value.map((datas, Index) => {
            let indexdata = Index + 1;
            return (
                <div key={Index + 'list'}>
					<li className="sortablecontainer-module-list custom-wrap">
					<div className='flex-block-box'>
                        {/* <i className="fa fa-chevron-right" aria-hidden="true"></i> */}
						<div>
						<b>{indexdata}. </b>
                        {datas}
						</div>
                       
						</div>
						<div className="sortablecontainer-module-edit-button form-btn-container">
                            {/* <button onClick={this.editsinglefield.bind(this, Index)} className='ft-edit-3 edit-btn-admin'>Edit</button>

                            <button onClick={this.deletesinglefield.bind(this, Index, this.state.field_ids[Index])} className='ft-trash-2 delete-btn-admin'>Delete</button> */}

							<a title="edit" href="#" onClick={this.editsinglefield.bind(this, Index)}>  <i class="ft-edit-3" aria-hidden="true"></i></a>

							<a title="delete" href="#" onClick={this.deletesinglefield.bind(this, Index, this.state.field_ids[Index])}>  <i class="ft-trash-2" aria-hidden="true"></i></a>
                        </div>
                    </li>
                </div>
            );
        });
        return formlayout;
    } else {
        return null; // or some fallback UI if the array is empty
    }
}

handlefieldTypeChange(event) {   
	this.setState({form_input_type:[event],form_input_type_value:event.value})
}
Addtolayout=()=>{

	var valuerequired;
  if(this.state.field_required_checked == true){
  valuerequired = 'yes';
  }else{
  valuerequired = 'no';
  }
	var Index = this.state.editindex;
  let fieldname = [...this.state.form_field_name_arr];
  let columnName = [...this.state.form_input_column_value_arr];
  let fieldtype = [...this.state.form_input_type_value_arr];
  let fieldrequired = [...this.state.field_required_arr];
  let fieldpriorty = [...this.state.field_priorty_arr]; 
  let selectoptarr = [...this.state.select_options_arr]; 
  let selectedDynarr = [...this.state.selectedDynamic_arr]; 
console.log(fieldname,'-fieldname')
console.log(columnName,'-columnName')
console.log(selectedDynarr,'-selectedDynarr')
  if(Index !==''){
  
	  fieldname.splice(Index, 1,this.state.form_field_name);	
	  columnName.splice(Index, 1,this.state.selectedColumnOptionValue);	
	  fieldtype.splice(Index, 1,this.state.form_input_type_value);
	  fieldrequired.splice(Index, 1,valuerequired);
	  fieldpriorty.splice(Index, 1,this.state.field_priorty);
	  if(this.state.select_options && this.state.select_options!==null){
	  selectoptarr.splice(Index, 1,this.state.select_options);
	  }	

	  if(this.state.selectedDynamicDropOptionValue && this.state.selectedDynamicDropOptionValue!==null){
		selectedDynarr.splice(Index, 1,this.state.selectedDynamicDropOptionValue);
		}	
	  this.setState({form_field_name_arr: fieldname,form_input_type_value_arr: fieldtype,field_required_arr:fieldrequired,field_priorty_arr:fieldpriorty,select_options_arr:selectoptarr,form_input_column_value_arr:columnName,selectedDynamic_arr:selectedDynarr});
  }else{
	  
	  this.setState({
		  form_field_name_arr: this.state.form_field_name_arr.concat(this.state.form_field_name), form_input_type_value_arr: this.state.form_input_type_value_arr.concat(this.state.form_input_type_value),field_priorty_arr: this.state.field_priorty_arr.concat(this.state.field_priorty),select_options_arr: this.state.select_options_arr.concat(this.state.select_options),field_required_arr: this.state.field_required_arr.concat(valuerequired),form_input_column_value_arr: this.state.form_input_column_value_arr.concat(this.state.selectedColumnOptionValue),selectedDynamic_arr: this.state.selectedDynamic_arr.concat(this.state.selectedDynamicDropOptionValue)
		  });
  }
  setTimeout(()=>{
	console.log(this.state.form_field_name_arr,'-this.state.form_field_name_arr')
	console.log(this.state.form_input_column_value_arr,'-this.state.form_input_column_value_arr')
	console.log(this.state.selectedDynamic_arr,'-this.state.selectedDynamic_arr')
	
  },500)

  this.setState({showlayout: true},function(){
  this.setState({form_field_name:'',form_input_type:'',form_input_type_value:'',field_required_checked:true,field_priorty:'',select_options:'',selectedDynamicDropOptionValue:'',selectedColumnOptionValue:''});
  this.getformlayout();
  });
}
handleFormSubmit = () => {

	this.setState({Loading:true});
				 const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };

				const formPayload = this.state;
				var qs = require('qs');
				var status = '';
				if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
				status =formPayload.selectedOption.value;
				}else{
				status = formPayload.selectedvalue;
				}
				
				var column_value = '';
				if(formPayload.form_one_column == true){
					column_value = 1;
				}else{
					column_value = 2;
				}
				var postObject = {
					admin_id : localStorage.getItem("admin_id"),
					form_id : this.state.form_id,
					field_ids : formPayload.field_ids,
					form_name: formPayload.form_name,
					form_column: column_value,
					form_field_name_arr: formPayload.form_field_name_arr,
					form_input_type_value_arr: formPayload.form_input_type_value_arr,
					field_priorty_arr: formPayload.field_priorty_arr,
					field_required_arr: formPayload.field_required_arr,
					select_options:formPayload.select_options_arr,
					column_rquired_arr: formPayload.form_input_column_value_arr,
					dynamicDrop_rquired_arr:formPayload.selectedDynamic_arr,
					type: formPayload.type,
					removeid: formPayload.remove_ids,
					status      :status				
				};
				console.log(formPayload.select_options_arr,'-select_options_arr')
				let formData = new FormData();
				for (let k in postObject) {
					if (Array.isArray(postObject[k])) {
						// Special handling for arrays like form_field_name_arr and form_input_type_value_arr
						if (k === 'form_field_name_arr' || k === 'form_input_type_value_arr' || k === 'field_priorty_arr'|| k === 'field_required_arr' || k === 'removeid' || k === 'field_ids' || k === 'column_rquired_arr') {
							// Join the values into a comma-separated string for these fields
							formData.append(k, postObject[k].join(','));
						} else if (k === 'select_options' || k === 'dynamicDrop_rquired_arr') {
							// For select_options, send as an array of comma-separated strings
							// postObject[k].forEach(optionGroup => {
							// 	formData.append(k, optionGroup); // Send each group as a single string
							// });
		
						postObject[k].forEach((item, index) => {
							formData.append(k + '[]', item); // PHP will interpret 'select_options[]' as an array
						});
						}
					} else {
						// For other fields, append them normally
						formData.append(k, postObject[k]);
					}
				}
	 
	 //this.props.getEditForm(qs.stringify(postObject));
	 axios.post(apiUrl + "formbuilder/edit", formData, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	}).then(res => {
		if(res.data.status === "success"){
			this.setState({ Loading: false });

			//const formpayload = res.data.rehablist;
			
			$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


			// setTimeout(
			// 	function() {
			// 	//	this.props.navigate('/formbuilder');
				
			// 	}
			// 	.bind(this),
			// 	3000
			// 	);
		} else {
			
			this.setState({ Loading: false });
			$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
				setTimeout(
				function() {
					$('.success_message').html('');
				}
				.bind(this),
				3000
				);
		}
	});

}
render() {
	//const navigate = this.props.navigate;
	const {selectedOption, selectedTableOption, selectedColumnOption, selectedDynamicDropOption} = this.state;
	//console.log(this.state.editindex)
return (
<div className="wrapper"> 
<Header />
<MenuSidebar currentpage="formbuilder" />  
<div className="content">	
	<div className="content-wrapper">
	<div class="content-wrapper-before"></div>
	<div className="form-wrapper leaa-admin-head card">
		<div className="success_message"></div>
		<div className="back-btn-lux">
                  	{/* <a onClick={() => navigate(-1)}>Back</a>     */}
            	</div>
			<div className="title">
				<h4>Edit Form</h4>
			</div>
		<div className="content-body">
	  
			<div className="builderpage-module-pagewrapper custom-pagewrapper">
		 <section className="builderpage-module-section custom-section">
			{(this.state.showlayout == true)?<> <h2 className="typography-module-title">Layout
				<div className="sortablecontainer-module-delete-btn"><button onClick={this.getdeleteall}>Delete All</button></div>

			</h2>

			   <div className="sortablecontainer-module-wrapper">
				 <ul>
				   {this.getformlayout()}
				 </ul>
			  </div></>:''}
		  </section>
	<form className="BuilderPage-module-form builderpage-module-section" onSubmit={ (e) => { e.preventDefault(); this.handleFormSubmit(this); }}>
	<h2 className="typography-module-title add-menu-title">Field Creator</h2>

		  <div className="form-group">
			  <label>Form Name:</label>
			  <input type="text" className="form-control" placeholder="Form Name" name="form_name" value={this.state.form_name} onChange={this.handleInputChange} autoComplete="off"/>
			</div>
			{/* <div className="form-group radio-check">
					<input className="form-check-input" type="radio" name="form_column" id="form_one_column" checked={this.state.form_one_column} onChange={this.handleInputChange}/>
					<label className="form-check-label" for="form_one_column">
					One Column
					</label>
					<input className="form-check-input" type="radio" name="form_column" id="form_two_column" checked={this.state.form_two_column} onChange={this.handleInputChange} />
					<label className="form-check-label" for="form_two_column">
					 Two Column
					</label>
			</div> */}
			<div className="form-group">					
			<label>Form Status:</label>
				   <Select 
				   value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
					options={lang.common.status_option} 
					onChange={this.handleChange}
					/>
		</div>
		  <div className="form-group">
		  <label>Name:</label>
			<input type="text" name="form_field_name" className="form-control" value={this.state.form_field_name} placeholder="Field Name" onChange={this.handleInputChange} autoComplete="off" />
		<div className="errorfieldname"></div>
		  </div>

		  <div className="form-group">		
							 <label>DB Identifier (Dev Purpose):</label>
								{this.state.selectedTableOption.value=='fund_form'?<Select 
									name="selectedColumnOption" 
									value={selectedColumnOption?selectedColumnOption:{ value: 'firstname', label: 'Firstname' }}
									 options={lang.common.quote_table_columns} 
									 onChange={this.handleChangeCommon}
									 />:<Select 
									 name="selectedColumnOption"
									 value={selectedColumnOption?selectedColumnOption:{ value: 'firstname', label: 'Firstname' }}
									  options={lang.common.user_table_columns} 
									  onChange={this.handleChangeCommon}
									  />}
						 </div>

		<div className="form-group">
		  <label>Type: </label>
		  <Select 
		  value={this.state.form_input_type!=''?this.state.form_input_type:'Choose'}
		  options={lang.common.input_field_options} 
		  onChange={this.handlefieldTypeChange.bind(this)}
		  placeholder= 'Field Type'
		  isSearchable={false}
		  />
			<div className="errortype"></div>
		</div>

		{this.state.form_input_type_value=='dynamic-dropdown'?<div className="form-group">		
							 <label>Dynamic Dropdown:</label>
								<Select 
									name="selectedDynamicDropOption" 
									value={selectedDynamicDropOption?selectedDynamicDropOption:{ value: '', label: 'Select' }}
									 options={lang.common.frontend_default_lable_option} 
									 onChange={this.handleChangeCommon}
									 />
						 </div>:''}


		 {(this.state.form_input_type_value == 'select' || this.state.form_input_type_value == 'checkbox' || this.state.form_input_type_value == 'radio')?<div className="form-group">
		<label>Options:</label>
		<input type="text" name="select_options" className="form-control" value={this.state.select_options} placeholder="Enter options separate by ," onChange={this.handleInputChange} autoComplete="off" />
		<div className="erroroptions"></div>
		</div>:''}
		<div className="form-group">
				<label>Field Required : </label>
				<input onChange={this.handleInputChange} type="checkbox"  name="field_required" checked={this.state.field_required_checked}/><span>Yes</span>
	   </div>
		<div className="form-group">
				<label>Priority : </label>
				 <input type="text" name="field_priorty" className="form-control" value={this.state.field_priorty} placeholder="Field Priority" onChange={this.handleInputChange} autoComplete="off" />
				 <div className="errorpriority"></div>
	   </div>
	  <div className="submit-button">
		 <span className="btn btn_orange btn_minwid login_submit animate-btn2" onClick={this.Addtolayout}>Update</span>

		 <div className="submit-center">	
		   <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
								<span className="load-data"></span> 
								}Submit
		   </button>
	</div>
	  </div>
	
	 </form>
  </div>
 
	  </div>	
	  </div>
  </div>	
</div>
</div>
);
}
}



export default withRouter(Edit);