import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";

import $ from 'jquery';
import {
  Row,
  Col,
  Modal,
  Table,
  OverlayTrigger,
  ButtonToolbar,
  Tooltip,
  Button
} from "react-bootstrap";
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';

import { GET_COMPANYLIST  } from '../../actions';
import parse from 'html-react-parser';
import axios from 'axios';
import { apiUrl, categoryURL} from'../Config/Config';

class List extends Component {
  
  constructor(props)
  {
    super(props); 
    this.state = {
      companylist:'',
      companyid:'',
      activePage: 1,
          totalRecord: '',
          search_all:'',
    };
    //this.props.getCompanyList();
    this.handleInputChange = this.handleInputChange.bind(this);

    }

   
   componentDidMount() {
	   this.companyloadedlist()
   }
   handlePageChange(pageNumber) {
    // console.log(`active page is ${pageNumber}`);
     this.setState({activePage: pageNumber});
     var sno_index = pageNumber - 1;
     this.setState({sno_index: sno_index});

    
     var postobject = {
       activePage: pageNumber,
       search_all: this.state.search_all,
    
     };
     
  this.companyloadedlist(postobject)

   }
   companyloadedlist = (postobject='') => {
    if(postobject!=''){
      var postobject=postobject;
    }else{
      var postobject = {
        activePage: 1,
        search_all: '',
       
        };
    }
    
  var qs = require('qs');
  axios.post(apiUrl+"company/list",qs.stringify(postobject)).then(res => {
    
    this.setState({
      companylist: res.data.companylist,totalRecord: res.data.records_count
    });
 
   //}
   }); 
}


   handleInputChange(event) {
   
  const {name, value} = event.target;      
  this.setState({
    [name]: value
  });
  
  var postobject = {
    activePage: 1,
    search_all: value,
  
    };
this.companyloadedlist(postobject);
  
}
   componentWillReceiveProps(Props){

   
   }

  companylist() {

   // var companylist = this.props.companylist[0];
    var companylistIS = this.state.companylist;
	console.log(companylistIS,'companylistIS');
	
    if (companylistIS != "undefined" && companylistIS != null) {
      
        if (Object.keys(companylistIS).length > 0) {
          const companylistDetails = companylistIS.map(
            (companylist, Index) => {
              let sno = Index+1;
              if(companylist.admin_status == 'Yes'){
                var status = 'Active';
              }else{
                  status = 'Inactive';
              }
              var domainlist = companylist.domains;
              let domvalu ='';
              if(domainlist!== undefined ){
                   domvalu  = domainlist.map((doms) => {
                   return doms.domain_name;
                  }).join(',');
              }else{
                domvalu = 'N/A';
              }
             
              var admin_contact = ((companylist.admin_contact == null)||(companylist.admin_contact == '')) ?  companylist.admin_contact = 'N/A' : companylist.admin_contact;
              return (
                <tr key={companylist.admin_id}>
                  <td>{sno}</td>
                  <td>
                  {companylist.admin_company_name}
                  </td>                 
                  {/* <td>{companylist.admin_email_address}</td> */}
                

                  <td>{companylist.admin_username}</td>
                  <td>{companylist.admin_org_password}</td>
                  <td>{admin_contact}</td>
                  <td>{status}</td>
                  <td className="actiongroup">
                    <Link to={{ pathname: '/company-edit/'+companylist.admin_id}} className="" title="Edit">  <i className="fa fa-edit" aria-hidden="true"></i></Link>
                    <Link onClick={this.copycompany.bind(this,companylist.admin_id)}  className="" title="Clone Company"><i className="fa fa-copy" aria-hidden="true"></i></Link>
                  </td>
                </tr>
              );
            }
          );
          return companylistDetails;
        }
      
    } else {
        return (
          <tr>
            <td colspan="6" className="v-align-nr">
              No Result
            </td>
          </tr>
        );
      }
  }
  
  copycompany(id){
    this.setState({companyid : id})
    $('.confirm-action').addClass('show');	
    
   }
   cloneConfirm =(confirmstatus)=>{
    if(confirmstatus == 'yes'){
			$('.confirm-action').removeClass('show');
		}else{
			$('.confirm-action').removeClass('show');	
		}
  }
  render() {
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="companylist" />  

  
  <div className="content"> 
    <div className="content-wrapper">
          <div class="content-wrapper-before"></div>
    <div className="car-listing-wrap leaa-admin-head card">

        <div className="listing-header"> 
          <div className="title">
            <h3>Companies</h3>
          </div> 
          <div className="search-list">
                   <form className="edit" id="edit" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} >     
                    <div className="search-inner">
                      <input type="text" name="search_all" onChange={this.handleInputChange} className="form-control" value={this.state.search_all} placeholder="Search" /> 
                     <button className="" type="submit">Submit</button>
                    <div className="erroremail"></div>
                  </div>
                 </form>
              </div>
          <div className="add_button">  
              <a className="add" href='/company-add'>Add</a> 
          </div>
        </div>

        <div className="car-listing-row table-avrech">
              <div className="overflow">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>S.No</th>
                <th>Company</th>               
				{/* <th>Email</th> */}
			
				<th>Username</th>
				<th>Password</th>
        <th>Contact</th>
        {/* <th>Domains</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{this.companylist()}</tbody>
          </Table>
          
        </div>
        <div className="text-center">
            <Pagination
            activePage={this.state.activePage}
            itemsCountPerPage={10}
            totalItemsCount={this.state.totalRecord}
            pageRangeDisplayed={5}
            onChange={this.handlePageChange.bind(this)}
            />
          </div>        
          </div>        
      </div>  
    </div>  
  </div>

  <div className="confirm-action">
								<p>Are you sure you want to clone ?</p>
                <Link  to={{ pathname: '/company-clone/'+this.state.companyid}} className="btn" title="Clone Company">Yes </Link>
                
								{/* <a href="javascript://" onClick={this.cloneConfirm.bind(this, "yes")} className="btn">Yes</a> */}
								<a href="javascript://" onClick={this.cloneConfirm.bind(this, "No")} className="btn">No</a>
								<span class="close-support close_popup"  onClick={this.cloneConfirm.bind(this, "No")}>X</span>
						 </div>
      
    </div>
    );
  }
}

/*const mapStateTopProps = (state) => {
  return {
    companylist: state.companylist,
   // deletecompany: state.deletecompany

  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCompanyList: () =>{
       dispatch({ type: GET_COMPANYLIST });
    },
   
  }
}*/
export default (withRouter(List));