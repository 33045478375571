import React, { Component } from 'react';
//import { useParams, useNavigate } from 'react-router-dom';
import { withRouter } from 'react-router-dom';

import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import $ from 'jquery';
import Select from 'react-select';
import { lang } from '../Helpers/lang';
import { PageTitle, CheckAuth, scrollToTopValidate, scrollToTop } from "../Helpers/SettingHelper";
import { apiUrl,  mediaUrl } from '../Config/Config';
import axios from 'axios';
import { Scrollbars } from 'react-custom-scrollbars';



// const withRouter = WrappedComponent => props => {
// 	const params = useParams();
// 	const  navigate = useNavigate();
// 	// etc... other react-router-dom v6 hooks
  
// 	return (
// 	  <WrappedComponent
// 		{...props}
// 		params={params}
// 		navigate ={navigate}
// 		// etc...
// 	  />
// 	);
//   };
class Add extends Component {	
	constructor(props)
	{
		CheckAuth();
		super(props);	
		this.state = {
			form_name:'',
			form_one_column:true,
			form_two_column:false,
			form_field_name:'',
			form_input_type:'',
			form_input_type_value:'',
			field_priorty:'',
			form_field_name_arr:[],
			form_input_type_arr:[],
			form_input_type_value_arr:[],
			field_priorty_arr:[],
			field_required_checked:true,
			field_required:'',
			field_required_arr:[],

			column_rquired_arr:[],
			dynamicDrop_rquired_arr:[],
			showlayout: false,
			select_options:'',
			select_options_arr:[],
			selectedvalue: '',
			selectedOption:{ value: 'active', label: 'Active'},
			selectedTableOption:{ value: 'fund_form', label: 'Fund Form'},
			selectedTableOptionValue:'',
			selectedColumnOption:{ value: 'firstname', label: 'Firstname' },
			selectedColumnOptionValue:'',
			selectedDynamicDropOption:{ value: '', label: '' },
			selectedDynamicDropOptionValue:'',
			Loading:false,
			table_for:'',
			column_for:'',
		};
		this.deletesinglefield = this.deletesinglefield.bind(this);
		

    }
	
	deletesinglefield(Index){
		let fieldname = [...this.state.form_field_name_arr];
		let fieldtype = [...this.state.form_input_type_value_arr];
		let coltype = [...this.state.column_rquired_arr];
		let fieldrequired = [...this.state.field_required_arr];
		let fieldpriorty = [...this.state.field_priorty_arr]; 

		let selectoptarr = [...this.state.select_options_arr]; 
		let selectdyrarr = [...this.state.dynamicDrop_rquired_arr]; 

		fieldname.splice(Index, 1);
		fieldtype.splice(Index, 1);  
		coltype.splice(Index, 1);  
		fieldrequired.splice(Index, 1);
		fieldpriorty.splice(Index, 1);
		selectoptarr.splice(Index, 1);
		selectdyrarr.splice(Index, 1);
		this.setState({form_field_name_arr: fieldname,form_input_type_value_arr: fieldtype,field_required_arr:fieldrequired,field_priorty_arr:fieldpriorty,column_rquired_arr:coltype,select_options_arr:selectoptarr,dynamicDrop_rquired_arr:selectdyrarr});
  
	}
	getformlayout(){
		var form_field_value = this.state.form_field_name_arr;
		if (Object.keys(form_field_value).length > 0) {
		const formlayout = form_field_value.map(
		(datas, Index) => {
		let indexdata = Index+1;
		return (
			  <div key={Index+'list'}>
			  <li className="sortablecontainer-module-list">
			  <i class="fa fa-chevron-right" aria-hidden="true"></i>
			  {datas}
			  <div className="sortablecontainer-module-edit-button">
			  {/*<button>Edit</button>*/}
			  <button onClick={this.deletesinglefield.bind(this,Index)} className='ft-trash-2 delete-btn-admin'>Delete</button>
			  </div>
			  </li></div> );
  
		}
		);
		return formlayout;
		}
	}
    handleChange = selectedOption => {
        this.setState({selectedOption})
        this.setState({  selectedvalue : selectedOption.value});
    };
	handleChangeCommon = (selectedOption, { name }) => {
		this.setState({
			[name]: selectedOption,  
			[`${name}Value`]: selectedOption.value 
		});
		console.log(name,'-name')
		console.log(`${name}Value`,'-value')
		console.log(selectedOption.value,'-selectedOption.value')
	};
	

	componentDidMount() {
		document.title = PageTitle('Company Add');
    }


        
afterSuccess(){
	$('.success_message').html('');
	//this.props.navigate('/company');
	
}
	
	handlefieldTypeChange(event) {   
		this.setState({form_input_type:[event],form_input_type_value:event.value})
	}
	handleInputChange = (event) =>{
		const { name,value } = event.target;
		this.setState({[name]: value})
		 if(name === 'form_column'){
		   this.setState({form_one_column: !this.state.form_one_column});
		   this.setState({form_two_column: !this.state.form_two_column});
		}
		if(name === 'field_required'){
			this.setState({field_required_checked: !this.state.field_required_checked});
		}
		if(name == 'select_options'){
			this.setState({select_options:value})
		}
	  }
	  Addtolayout1=()=>{
		if(this.validateFormfield()){
			  var valuerequired;
			  if(this.state.field_required_checked == true){
			  valuerequired = 'yes';
			  }else{
			  valuerequired = 'no';
			  }
			  this.setState({
			  form_field_name_arr: this.state.form_field_name_arr.concat(this.state.form_field_name), form_input_type_value_arr: this.state.form_input_type_value_arr.concat(this.state.form_input_type_value),field_priorty_arr: this.state.field_priorty_arr.concat(this.state.field_priorty),select_options_arr: this.state.select_options_arr.concat(this.state.select_options),field_required_arr: this.state.field_required_arr.concat(valuerequired)
			  });
			  this.setState({showlayout: true},function(){
			  this.setState({form_field_name:'',form_input_type:'',form_input_type_value:'',field_required_checked:true,field_priorty:'',select_options:''});
			  this.getformlayout();
			  })
		}
	}

	Addtolayout=()=>{
		if(this.validateFormfield()){
			  var valuerequired;
			  if(this.state.field_required_checked == true){
			  valuerequired = 'yes';
			  }else{
			  valuerequired = 'no';
			  }
			  this.setState({
			  form_field_name_arr: this.state.form_field_name_arr.concat(this.state.form_field_name), form_input_type_value_arr: this.state.form_input_type_value_arr.concat(this.state.form_input_type_value),field_priorty_arr: this.state.field_priorty_arr.concat(this.state.field_priorty),select_options_arr: this.state.select_options_arr.concat(this.state.select_options),field_required_arr: this.state.field_required_arr.concat(valuerequired),column_rquired_arr: this.state.column_rquired_arr.concat(this.state.selectedColumnOptionValue)
			  ,dynamicDrop_rquired_arr: this.state.dynamicDrop_rquired_arr.concat(this.state.selectedDynamicDropOptionValue)
			  });
			  
			  
			  this.setState({showlayout: true},function(){
			  this.setState({form_field_name:'',form_input_type:'',form_input_type_value:'',field_required_checked:true,field_priorty:'',select_options:'',selectedColumnOptionValue:'',selectedColumnOption:'',selectedDynamicDropOptionValue:'',selectedDynamicDropOption:''});
			  this.getformlayout();
			  })
		}
	}
	handleFormSubmit = () => {
		//console.log('rtest')
		this.setState({Loading:true});
		 const config = {
			headers: {
			  'Content-Type': 'multipart/form-data'
			}
		  };

		const formPayload = this.state;
		var qs = require('qs');
		var status = '';
		if(formPayload.selectedvalue === '' && formPayload.selectedvalue!== 0){
		status =formPayload.selectedOption.value;
		}else{
		status = formPayload.selectedvalue;
		}
		
		var column_value = '';
		if(formPayload.form_one_column == true){
			column_value = 1;
		}else{
			column_value = 2;
		}
		var postObject = {
			admin_id : localStorage.getItem("admin_id"),
			form_name: formPayload.form_name,
			form_column: 1,
			form_type: formPayload.selectedTableOptionValue,
			form_field_name_arr: formPayload.form_field_name_arr,
			form_input_type_value_arr: formPayload.form_input_type_value_arr,
			column_rquired_arr: formPayload.column_rquired_arr,
			dynamicDrop_rquired_arr: formPayload.dynamicDrop_rquired_arr,
			field_priorty_arr: formPayload.field_priorty_arr,
			field_required_arr: formPayload.field_required_arr,
			select_options:formPayload.select_options_arr,
			type: formPayload.type,
			status      :status				
		};

		let formData = new FormData();
				// for(let k in postObject) {
				// formData.append(k, postObject[k]);
				// }

		// for (let k in postObject) {
		// 	if (Array.isArray(postObject[k])) {
		// 		// Loop through arrays to do select_options and append each item separately
		// 		postObject[k].forEach(item => {
		// 			formData.append(k, item);
		// 		});
		// 	} else {
		// 		// Append other fields directly
		// 		formData.append(k, postObject[k]);
		// 	}
		// }
		
		// for (let k in postObject) {
		// 	if (Array.isArray(postObject[k])) {
		// 		// For arrays (like select_options), send each value with the key as an array notation (select_options[])
		// 		postObject[k].forEach((item, index) => {
		// 			formData.append(k + '[]', item); // PHP will interpret 'select_options[]' as an array
		// 		});
		// 	} else {
		// 		// For other fields, append as usual
		// 		formData.append(k, postObject[k]);
		// 	}
		// }

		for (let k in postObject) {
			if (Array.isArray(postObject[k])) {
				// Special handling for arrays like form_field_name_arr and form_input_type_value_arr
				if (k === 'form_field_name_arr' || k === 'form_input_type_value_arr' || k === 'field_priorty_arr'|| k === 'field_required_arr' || k === 'column_rquired_arr') {
					// Join the values into a comma-separated string for these fields
					formData.append(k, postObject[k].join(','));
				} else if (k === 'select_options' || k === 'dynamicDrop_rquired_arr') {
					// For select_options, send as an array of comma-separated strings
					// postObject[k].forEach(optionGroup => {
					// 	formData.append(k, optionGroup); // Send each group as a single string
					// });

				postObject[k].forEach((item, index) => {
					formData.append(k + '[]', item); // PHP will interpret 'select_options[]' as an array
				});
				}
			} else {
				// For other fields, append them normally
				formData.append(k, postObject[k]);
			}
		}
		//axios.post(apiUrl + "formbuilder/add", postObject, {headers: {'Content-Type': 'multipart/form-data'}}).then(res => {
		axios.post(apiUrl+"formbuilder/add",formData).then(res => {	
			if(res.data.status === "success"){
				this.setState({ Loading: false });

				//const formpayload = res.data.rehablist;
				
				$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');


				setTimeout(
					function() {
						//this.props.navigate('/formbuilder');
						$('.success_message').html(this.props.history.push('/formbuilder'));
					}
					.bind(this),
					3000
					);
			} else {
				
				this.setState({ Loading: false });
				$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
					setTimeout(
					function() {
						$('.success_message').html('');
					}
					.bind(this),
					3000
					);
			}
		});
		//this.props.getAddForm(qs.stringify(postObject));

}
	validateFormfield() { //console.log('validationFormField')
		const {form_field_name,form_input_type_value,field_required,field_priorty,select_options} = this.state;
		let errors = 0;
	
		if (!form_field_name) {
			errors++;
			$('.errorfieldname').html('<span class="errorspan">Please fill the field</span>');
		}else if(form_field_name){
			$('.errorfieldname').html('');
		}


		if (!form_input_type_value) {
			errors++;
			$('.errortype').html('<span class="errorspan">Please select input type</span>');
		}else if(form_input_type_value){
			$('.errortype').html('');
		}

		if(!field_priorty){
		errors++;
			$('.errorpriority').html('<span class="errorspan">Please fill the field</span>');
		}else if(field_priorty){
			$('.errorpriority').html('');
		}

		if(form_input_type_value == 'select' || form_input_type_value == 'checkbox' || form_input_type_value == 'radio'){
			if(!select_options){
				errors++;
				$('.erroroptions').html('<span class="errorspan">Please fill the field</span>');
			}else if(select_options){
				$('.erroroptions').html('');
			}
		}

		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}

    }
  render() { 
	console.log(this.state.form_input_type_value,'form_input_type_value')
	//const navigate = this.props.navigate;
  	  const {selectedOption, selectedTableOption, selectedColumnOption, selectedDynamicDropOption} = this.state;
		return (
			<div className="wrapper"> 
			 <Header />
			 <MenuSidebar currentpage="formbuilder" />  
				 <div className="content">	
					 <div className="content-wrapper">
					 <div class="content-wrapper-before"></div>
					 <div className="form-wrapper leaa-admin-head card">
						 <div className="success_message"></div>
						 <div className="back-btn-lux">
                  	{/* <a onClick={() => navigate(-1)}>Back</a>     */}
            	</div>
							 <div className="title">
								 <h4>Add Form</h4>
							 </div>
						 <div className="content-body">
					   
							 <div className="builderpage-module-pagewrapper custom-wrapper">
						  <section className="builderpage-module-section custom-section" >
							 {(this.state.showlayout == true)?<> <h2 className="typography-module-title">Form Field
								<div className="sortablecontainer-module-delete-btn"><button onClick={this.getdeleteall}>Delete All</button></div>
							 </h2>
								<div className="sortablecontainer-module-wrapper">
								  <ul>
									{this.getformlayout()}
								  </ul>
								 
							   </div></>:''}
						   </section> 
					 <form className="BuilderPage-module-form builderpage-module-section"  onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }}>
		 
						   <div className="form-group">
					 <h2 className="typography-module-title add-menu-title">Field Creator</h2>

							   <label>Form Name:</label>
							   <input type="text" className="form-control" placeholder="Form Name" name="form_name" value={this.state.form_name} onChange={this.handleInputChange} autoComplete="off"/>
							 </div>
							 {/* <div className="form-group radio-check">
							 <label>Choose layout</label>
									 <input className="form-check-input" type="radio" name="form_column" id="form_one_column" checked/>
									 <label className="form-check-label" for="form_one_column">
									 One Column
									 </label>
									 <input className="form-check-input" type="radio" name="form_column" id="form_two_column" />
									 <label className="form-check-label" for="form_two_column">
									  Two Column
									 </label>
							 </div> */}
							 <div className="form-group">					
							 <label>Form Type:</label>
									<Select 
									name="selectedTableOption" 
									value={selectedTableOption?selectedTableOption:{ value: 'fund_form', label: 'Fund Form'}}
									 options={lang.common.table_for} 
									 onChange={this.handleChangeCommon}
									 />
						 </div>
						 <div className="form-group">					
							 <label>Status:</label>
									<Select 
									value={selectedOption?selectedOption:{ value: 'active', label: 'Active'}}
									 options={lang.common.status_option} 
									 onChange={this.handleChange}
									 />
						 </div>
						 
						   <div className="form-group">
						   <label>Name:</label>
							 <input type="text" name="form_field_name" className="form-control" value={this.state.form_field_name} placeholder="Field Name" onChange={this.handleInputChange} autoComplete="off" />
						 <div className="errorfieldname"></div>
						   </div>

						   <div className="form-group">		
							 <label>DB Identifier (Dev Purpose):</label>
								{this.state.selectedTableOption.value=='fund_form'?<Select 
									name="selectedColumnOption" 
									value={selectedColumnOption?selectedColumnOption:{ value: 'firstname', label: 'firstname' }}
									 options={lang.common.quote_table_columns} 
									 onChange={this.handleChangeCommon}
									 />:<Select 
									 name="selectedColumnOption"
									 value={selectedColumnOption?selectedColumnOption:{ value: 'firstname', label: 'firstname' }}
									  options={lang.common.user_table_columns} 
									  onChange={this.handleChangeCommon}
									  />}
						 </div>

						 <div className="form-group">
						   <label>Type: </label>
						   <Select 
						   value={this.state.form_input_type!=''?this.state.form_input_type:'Choose'}
						   options={lang.common.input_field_options} 
						   onChange={this.handlefieldTypeChange.bind(this)}
						   placeholder= 'Field Type'
						   isSearchable={false}
						   />
							 <div className="errortype"></div>
						 </div>

						 {this.state.form_input_type_value=='dynamic-dropdown'?<div className="form-group">		
							 <label>Dynamic Dropdown:</label>
								<Select 
									name="selectedDynamicDropOption" 
									value={selectedDynamicDropOption?selectedDynamicDropOption:{ value: '', label: 'Select' }}
									 options={lang.common.frontend_default_lable_option} 
									 onChange={this.handleChangeCommon}
									 />
						 </div>:''}


						 {(this.state.form_input_type_value == 'select' || this.state.form_input_type_value == 'checkbox' || this.state.form_input_type_value == 'radio')?<div className="form-group">
						 <label>Options:</label>
						 <input type="text" name="select_options" className="form-control" value={this.state.select_options} placeholder="Enter options separate by ," onChange={this.handleInputChange} autoComplete="off" />
						 <div className="erroroptions"></div>
						 </div>:''}
						 <div className="form-group">
								 <label>Field Required : </label>
								 <input onChange={this.handleInputChange} type="checkbox"  name="field_required" checked={this.state.field_required_checked}/><span>Yes</span>
						</div>
						 <div className="form-group">
								 <label>Priority : </label>
								  <input type="text" name="field_priorty" className="form-control" value={this.state.field_priorty} placeholder="Field Priority" onChange={this.handleInputChange} autoComplete="off" />
								  <div className="errorpriority"></div>
						</div>
					   <div className="submit-button">
						  <span className="btn btn_orange btn_minwid login_submit animate-btn2" onClick={this.Addtolayout}>Add</span>
					   </div>
					   
					   <div className="submit-center">	
							<button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
												 <span className="load-data"></span> 
												 }Submit
							</button>
					 </div>

					  </form>
				   </div>
				  
					   </div>	
				   </div>

				   </div>	
			   </div>
		   </div>
		   );
  }
}


 
export default withRouter(Add);