import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import $ from 'jquery';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import axios from 'axios';
import { apiUrl, categoryURL} from'../Config/Config';
import { PageTitle } from "../Helpers/SettingHelper";
import { GET_VIEWCATEGORYLIST } from '../../actions';

class View extends Component {
  
  constructor(props)
  {
    super(props); 
    const flagid = (typeof this.props.match.params.ID === 'undefined') ? '' : this.props.match.params.ID;

    this.state = {      
      flagid: flagid,
      Bookkeepinglisting:'',
    };

    var qs = require('qs');
   


    axios.get(apiUrl+"bookkeeping/listdetail?id="+flagid).then(res => {
			
			this.setState({flag_name:res.data.BookkeepingDetails.flag_name});

      this.setState({ Bookkeepinglisting: res.data.BookkeepingDetails});
			if(res.data.BookkeepingDetails.status === 'A'){
				this.setState({selectedOption:{value: 'active', label: 'Active'}});
			}else{
			this.setState({selectedOption:{value: 'inactive', label: 'Inactive'}});
			}
		}); 
   
  }
   
	

		
   componentDidMount() {
     document.title = PageTitle('Category View');
   }
  render() {
    if(this.state.Bookkeepinglisting!==undefined){
      var Bookkeepinglisting = this.state.Bookkeepinglisting;
    }
  

 
		
  
    return (
      <div className="wrapper"> 
 
    <Header />
    <MenuSidebar currentpage="bookkeepingcategory" />  
      
      <div className="content"> 
        <div className="content-wrapper">
        <div class="content-wrapper-before"></div>
            <div className="content-body">
               <div className="form-wrapper leaa-admin-head card">
                <div className="title">
                  <h3>Bookkeeping </h3>
                  <a className="" href='/bookkeepingcategory'><span class="close">X</span></a>	
                </div>
                <div className="form-row">
                  <div className="details_left">
                      <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Bookkeeping Name<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{Bookkeepinglisting.name}</p>
                        </div>
                      </div>
                    
                        <div className="view-details">
                        <div className="view-details-one">
                            <p><b>Status<span>:</span> </b></p>
                        </div>
                        <div className="view-details-two">
                            <p>{(Bookkeepinglisting.status=='A')?'Active':'Inactive'}</p>
                        </div>
                      </div>
                      
                    

                      </div>
                      </div>
               
                    
                     
                    
                    <div className="map-wrapper">                      
                     <div id="map"></div>
                     <div className="mapbox-gl-marker"></div>
                    </div>
              </div>            
            
          </div>
         </div> 
        </div>        
    </div>
    );
  }
}

export default (withRouter(View));