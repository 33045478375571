 /*local */

// export const apiUrl      = "http://localhost/fund/api/";
// export const baseUrl      = "http://localhost/fund";
// export const mediaUrl      = "http://localhost/fund/media/";
// export const carImageUrl      = "http://localhost/fund/media/cars";
// export const sampleFileUrl      = "http://localhost/fund/media/sample_file/";
// export const cmscontentURL       = "http://localhost/fund/media/cmscontent";
// export const categoryURL       = "http://localhost/fund/media/category";

// export const licenseURL       = "http://localhost/fund/media/brokerlicense";
// export const insuranceURL       = "http://localhost/fund/media/brokerinsurance";
// export const profileURL       = "http://localhost/fund/media/brokerprofile";
// export const brokerURL       = "http://localhost/fund/media/brokerimage";
// export const galleryUrl      = 'http://localhost/fund/media/gallery';
// export const guideUrl      = 'http://localhost/fund/media/guide';
// export const favouritesUrl      = 'http://localhost/fund/media/favourites'; 


/*Live */


export const apiUrl      = "https://a-fund.uxt.design/fund/api/";
export const baseUrl      = "https://a-fund.uxt.design/fund";
export const mediaUrl      = "https://a-fund.uxt.design/fund/media/";
export const carImageUrl      = "https://a-fund.uxt.design/fund/media/cars";
export const sampleFileUrl      = "https://a-fund.uxt.design/fund/media/sample_file/";
export const cmscontentURL       = "https://a-fund.uxt.design/fund/media/cmscontent";
export const categoryURL       = "https://a-fund.uxt.design/fund/media/category";

export const licenseURL       = "https://a-fund.uxt.design/fund/media/brokerlicense";
export const insuranceURL       = "https://a-fund.uxt.design/fund/media/brokerinsurance";
export const profileURL       = "https://a-fund.uxt.design/fund/media/brokerprofile";
export const brokerURL       = "https://a-fund.uxt.design/fund/media/brokerimage";
export const galleryUrl      = 'https://a-fund.uxt.design/fund/media/gallery';
export const favouritesUrl      = 'https://a-fund.uxt.design/fund/media/favourites'; 


/*Dev*/

// export const apiUrl      = "https://admin-lender.uxt.design/fund/api/";
// export const baseUrl      = "https://admin-lender.uxt.design/fund";
// export const mediaUrl      = "https://admin-lender.uxt.design/fund/media/";
// export const carImageUrl      = "https://admin-lender.uxt.design/fund/media/cars";
// export const sampleFileUrl      = "https://admin-lender.uxt.design/fund/media/sample_file/";
// export const cmscontentURL       = "https://admin-lender.uxt.design/fund/media/cmscontent";
// export const categoryURL       = "https://admin-lender.uxt.design/fund/media/category";

// export const licenseURL       = "https://admin-lender.uxt.design/fund/media/brokerlicense";
// export const insuranceURL       = "https://admin-lender.uxt.design/fund/media/brokerinsurance";
// export const profileURL       = "https://admin-lender.uxt.design/fund/media/brokerprofile";
// export const brokerURL       = "https://admin-lender.uxt.design/fund/media/brokerimage";
// export const galleryUrl      = 'https://admin-lender.uxt.design/fund/media/gallery';
// export const favouritesUrl      = 'https://admin-lender.uxt.design/fund/media/favourites'; 
