/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import $ from 'jquery';
import CKEditor from 'ckeditor4-react';
import Header from '../Layouts/Header';
import MenuSidebar from '../Layouts/MenuSidebar';
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import { apiUrl, cmscontentURL,mediaUrl} from'../Config/Config';

import { GET_SETTINGDATA, GET_UPDATESETTING, GET_FONTNAME } from '../../actions';
import Select from 'react-select';
import axios from 'axios';
import { scrollToTopValidate, PageTitle,scrollToTop } from "../Helpers/SettingHelper";
import Addinputsimple from './Addinputsimple';
import { lang } from '../Helpers/lang';

class Setting extends Component {
	
	constructor(props)
	{
		super(props);	
		this.state = {
			settings_id:'',
			settings_site_title:'',
			settings_from_name:'',
			settings_admin_email:'',
			settings_from_email:'',
			settings_mailpath:'',
			settings_company_address:'',
			settings_commission_percentage:'',
			settings_smtp_host:'',
			settings_smtp_user:'',
			settings_smtp_pass:'',
			settings_smtp_port:'',
			settings_username:'',
			settings_mobileno:'',
			settings_email_footer:'',
			settings_commision_amount:'',
			merchant_commision_to_broker:'',
			lender_purchase_count:'',
			smtp_checked:false,
			Loading: false,
			displayColorPicker: false,
			color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
			},
			hexColor:'#f17013',
			settings_public_key:'',
			settings_product_key:'',
			settings_secret_key:'',
			primary_font:'',
			main_tab_font_size:'',
			menu_font_size:'',
			stripe_live_checked:false,
			stripe_sandbox_checked:false,
			settings_embedcode:'',
			setting_goal_amount:'',
			settings_access_code:'',
			st_position_left:'',
			st_position_right:'',
			st_position_top:'',
			st_position_bottom:'',
			domainSets:'',
			removeID:[
        	],
			fonttype : [],
			selectedFont:'',
			fontvalue:'',
			st_position_checked:true,
			st_position:'',
			supporter_additional_fee:'',
			product_layout2_checked:true,
			product_layout3_checked:false,
			google_analystic_code:'',
			category_icons_yes:true,
			category_icons_no:false,
			maintab_enable_yes:true,
			maintab_enable_no:false,
			terms_and_condition: '',
			np_theme:'',
			header_cat_limit:1,
			invite_friends_perc:1,
			count_domain:'',
			faqmodelcommon:[{"value":"gpt-3.5-turbo","label":"gpt-3.5-turbo"},{"value":"gpt-4-0613","label":"gpt-4-0613"}],
			faqmodelguest: '',
			faqmodeluser: '',
			faqmodelbroker: '',
			faqmodelcompany:'',
			faqmodellender:'',
			selectedfaqmodelcommonval:'',
			selectedfaqmodeluserval:'',
			selectedfaqmodelguestval:'',
			selectedfaqmodelbrokerval:'',
			selectedfaqmodelcompanyval:'',
			selectedfaqmodellenderval:'',
			selectedfaqmodelcommon:'',
			selectedfaqmodelguest:'',
			selectedfaqmodelbroker:'',
			selectedfaqmodelcompany:'',
			selectedfaqmodellender:'',
			selectedfaqmodeluserval:'',
			ai_fine_tune_model_id:'',
			aichat_type:"text",
			leads_tax:'',
            leads_purchase_amount:'',
            selectedOption:'',
			admin_id:localStorage.getItem('admin_id'),
			  module_type : localStorage.getItem("module_type"),
			  lender_invite_desc : '',
			  investor_invite_desc : '',
			  old_lender_invite_img : '',
			  old_investor_invite_img : '',
			  description: '',
			  selectedFontVariant:'',
				st_varient_id: [],
				landing_page:'InnerTab'

		};

		var qs = require('qs');
		
		var postobject = {
			admin_id:  localStorage.getItem("admin_id")
		};

		//this.props.getSettingData(qs.stringify(postobject));

		axios.post(apiUrl+"adminpanel/adminsettings",qs.stringify(postobject)).then(res => {
				if(res.data.results){
					const formpayload = res.data.results;
					this.setState({settings_id:formpayload.settings_id});
					this.setState({terms_and_condition:formpayload.settings_consent_content});
					this.setState({settings_site_title:formpayload.settings_site_title});
					this.setState({settings_from_name:formpayload.settings_from_name});
					this.setState({settings_admin_email:formpayload.settings_admin_email});
					this.setState({settings_from_email:formpayload.settings_from_email});
					this.setState({settings_mailpath:formpayload.settings_mailpath});
					this.setState({settings_company_address:formpayload.settings_company_address});
					this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
					this.setState({settings_smtp_host:formpayload.settings_smtp_host});
					this.setState({settings_smtp_user:formpayload.settings_smtp_user});
					this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
					this.setState({settings_smtp_port:formpayload.settings_smtp_port});
					this.setState({settings_username:formpayload.settings_username});
					this.setState({settings_mobileno:formpayload.settings_mobileno});
					this.setState({settings_email_footer:formpayload.settings_email_footer});
					this.setState({hexColor:formpayload.settings_site_background_color});
					this.setState({settings_public_key:formpayload.settings_public_key});
					this.setState({settings_product_key:formpayload.settings_product_key});
					this.setState({setting_goal_amount:formpayload.setting_goal_amount});
					this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
					this.setState({settings_secret_key:formpayload.settings_secret_key});
					this.setState({primary_font:formpayload.primary_font});
					this.setState({main_tab_font_size:formpayload.main_tab_font_size});
					this.setState({menu_font_size:formpayload.menu_font_size});
					this.setState({settings_embedcode:formpayload.settings_embedcode});
					this.setState({settings_access_code:formpayload.access_code});				
					this.setState({st_position_left:formpayload.st_position_left});
					this.setState({st_position_right:formpayload.st_position_right});
					this.setState({st_position_top:formpayload.st_position_top});
					this.setState({st_position_bottom:formpayload.st_position_bottom});
					this.setState({domainSets : formpayload.domainSets});
					this.setState({settings_commision_amount: formpayload.settings_commision_amount});
					this.setState({merchant_commision_to_broker: formpayload.merchant_commision_to_broker});
					this.setState({lender_purchase_count: formpayload.lender_purchase_count});										
					this.setState({header_cat_limit: formpayload.header_cat_limit});
					this.setState({np_theme: formpayload.notification_popup_theme});
					this.setState({invite_friends_perc: formpayload.invite_friends_perc});
					this.setState({leads_tax:formpayload.leads_tax});		
					this.setState({leads_purchase_amount:formpayload.leads_purchase_amount});		
					this.setState({lender_invite_desc:formpayload.lender_invitation_desc});		
					this.setState({investor_invite_desc:formpayload.investor_invitation_desc});			
					this.setState({chat_api_key:formpayload.chat_api_key});			
					
					this.setState({landing_page:formpayload.landing_page});

					if(formpayload.lender_invitation_template !== null){
						this.setState({old_lender_invite_img:formpayload.lender_invitation_template});
					}
					if(formpayload.investor_invitation_template !== null){
						this.setState({old_investor_invite_img:formpayload.investor_invitation_template});
					}

					if(formpayload.settings_faq_common_model_id!== 'null' && formpayload.settings_faq_common_model_id !== ''){
						this.setState({selectedfaqmodelcommon: {value:formpayload.settings_faq_common_model_id, label:formpayload.settings_faq_common_model_id},selectedfaqmodelcommonval:formpayload.settings_faq_common_model_id });
					}

					if(formpayload.settings_faq_user_model_id!== 'null' && formpayload.settings_faq_user_model_id !== ''){
						this.setState({selectedfaqmodeluser: {value:formpayload.settings_faq_user_model_id, label:formpayload.settings_faq_user_model_id},selectedfaqmodeluserval:formpayload.settings_faq_user_model_id });
					}
					if(formpayload.settings_faq_guest_model_id!== 'null' && formpayload.settings_faq_guest_model_id !== ''){
						this.setState({selectedfaqmodelguest: {value:formpayload.settings_faq_guest_model_id, label:formpayload.settings_faq_guest_model_id},selectedfaqmodelguestval:formpayload.settings_faq_guest_model_id});
					}
					if(formpayload.settings_faq_doctor_model_id!== 'null' && formpayload.settings_faq_doctor_model_id !== ''){
						this.setState({selectedfaqmodelbroker: {value:formpayload.settings_faq_doctor_model_id, label:formpayload.settings_faq_doctor_model_id},selectedfaqmodelbrokerval:formpayload.settings_faq_doctor_model_id});
					}
					if(formpayload.settings_faq_company_model_id!== 'null' && formpayload.settings_faq_company_model_id !== ''){
						this.setState({selectedfaqmodelcompany: {value:formpayload.settings_faq_company_model_id, label:formpayload.settings_faq_company_model_id},selectedfaqmodelcompanyval:formpayload.settings_faq_company_model_id });
					}
					if(formpayload.settings_faq_technician_model_id!== 'null' && formpayload.settings_faq_technician_model_id !== ''){
						this.setState({selectedfaqmodellender: {value:formpayload.settings_faq_technician_model_id, label:formpayload.settings_faq_technician_model_id},selectedfaqmodellenderval:formpayload.settings_faq_technician_model_id });
					}
					this.setState({aichat_type: formpayload.ai_model_type});
					this.setState({ai_fine_tune_model_id: formpayload.ai_fine_tune_model_id});
	
					if( formpayload.google_analystic_code !== 'null'){
						this.setState({google_analystic_code: formpayload.google_analystic_code});
					}else{
						this.setState({google_analystic_code: ''});
					}
					
					if(formpayload.settings_mail_from_smtp === '1'){
						this.setState({smtp_checked: true});
					}else{
						this.setState({smtp_checked: false});
					}
					if(formpayload.pluginwidth === '600'){
									
						this.setState({selectedOption:{value: '600', label: '600px'}});
					}else{
						
						this.setState({selectedOption:{value: '630', label: '630px'}});
		
					}
					if(formpayload.enable_live === '1'){
						this.setState({stripe_live_checked: true,stripe_sandbox_checked:false });
					}else{
						this.setState({stripe_sandbox_checked: true,stripe_live_checked:false});
					}
	
					if(formpayload.st_plugin_position === 'fixed'){
						this.setState({st_position_checked: true });
					}else{
						this.setState({st_position_checked: false});
					}
	
					if(formpayload.product_layout === '2'){
						this.setState({product_layout2_checked: true });
						this.setState({product_layout3_checked: false});
					}else{
						this.setState({product_layout3_checked: true});
						this.setState({product_layout2_checked: false });
					}
	
					if(formpayload.show_category_icons === 'yes'){
						this.setState({category_icons_yes: true });
						this.setState({category_icons_no: false});
					}else{
						this.setState({category_icons_no: true});
						this.setState({category_icons_yes: false });
					}

					// if(formpayload.is_maintab_enable === 'yes'){
					// 	this.setState({maintab_enable_yes: true });
					// 	this.setState({maintab_enable_no: false});
					// }else{
					// 	this.setState({maintab_enable_yes: false});
					// 	this.setState({maintab_enable_no: true });
					// }
	
					if(res.data.font!== '' && res.data.font!== null){
						this.setselectedvalue(res.data.font);
					}
					if(res.data.fontvarientlist!== '' && res.data.fontvarientlist!== null){
						this.setselectedvariants(res.data.fontvarientlist);
					}
				}
			});

		var admin_id = localStorage.getItem('admin_id');
		axios.get(apiUrl+'font/getfont_name?admin_id='+admin_id)
			
			.then(res => {
				if(res.data.status == 'success'){
				
					this.setState({
						fonttype: res.data.fontlist
					})
				
				}else{
				//console.log("test")
				}
			});
		this.myCallback();
		this.handleInputChange  = this.handleInputChange.bind(this);
		
    }
 onEditorAIChange = (event) => {
        this.setState({ description: event.editor.getData() });
    };
    handleInputChange(event) {

      const {name, value} = event.target;      
      this.setState({
          [name]: value
        });
      if(name === 'settings_mail_from_smtp'){
      	 this.setState({smtp_checked: !this.state.smtp_checked});
      }	
      if(name === 'settings_enable_live'){
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      	  this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      }	
      if(name === 'settings_enable_sandbox'){
      	 this.setState({stripe_sandbox_checked: !this.state.stripe_sandbox_checked});
      	 this.setState({stripe_live_checked: !this.state.stripe_live_checked});
      }	
      if(name === 'product_layout'){
      	 this.setState({product_layout2_checked: !this.state.product_layout2_checked});
      	 this.setState({product_layout3_checked: !this.state.product_layout3_checked});
      }

	  if(name === 'category_icons'){
		this.setState({category_icons_yes: !this.state.category_icons_yes});
		this.setState({category_icons_no: !this.state.category_icons_no});
     }
	//  if(name === 'enable_maintab'){
	// 	this.setState({maintab_enable_yes: !this.state.maintab_enable_yes});
	// 	this.setState({maintab_enable_no: !this.state.maintab_enable_no});
    //  }


      if(name === 'st_position'){
      	this.setState({st_position_checked: !this.state.st_position_checked});
      }
    }
	
    componentDidMount() {

		var admin_id =  localStorage.getItem("admin_id")
		axios.get(apiUrl+"adminpanel/getchatmodel?admin_id="+admin_id)
			.then(res => {			
				//console.log('faqmodeluserfaqmodeluserfaqmodeluser' , res.data)	
				if(res.data.status == 'success'){
					this.setState({
						faqmodelguest: res.data.faqmodelguest,
						faqmodeluser: res.data.faqmodeluser,
						faqmodelbroker: res.data.faqmodelbroker,
						faqmodellender: res.data.faqmodellender,
						faqmodelcompany: res.data.faqmodelcompany,
					});		
				}
			});

      document.title = PageTitle('Settings');
      if(localStorage.getItem('admin_id') === null){
      this.props.history.push('/');
      }
	  let body = document.querySelectorAll("body")[0];
	if(localStorage.getItem('admin_id')){
		body.classList.remove("body-theme");
	}else{
		body.classList.add("body-theme");
	}

	$('.num_valid').on('keypress', function(){
		var val = $(this).val();
		var length = val.length;
		var maxlength = $(this).attr("maxlength");
		
		if(length >= maxlength){
			return false;
		}
	})
     }
	 


    handleFormSubmit = () => {
			if(this.validateForm()){
				this.setState({Loading:true});
				
				const config = {
			        headers: {
			          'Content-Type': 'multipart/form-data'
			        }
			      };
				  
				const formPayload = this.state;

				var qs = require('qs');
				if(this.state.smtp_checked === true){
					var settings_mail_from_smtp = '1';
				}else{
					 settings_mail_from_smtp = '0';
				}
				if(this.state.stripe_live_checked === true){
					var settings_enable_live = '1';
				}else{
					 settings_enable_live = '0';
				}

				if(this.state.st_position_checked === true){
					var plugin_position = 'fixed';
				}else{
					 plugin_position = 'absolute';
				}
                
				var fonttype;
				if(formPayload.selectedFont[0]==undefined){
					fonttype = formPayload.selectedFont.value;
				}else{
					fonttype = formPayload.selectedFont[0].value;
				}
				var product_layout = '';
				if(this.state.product_layout3_checked === true){
					 product_layout = '3';
				}else{
					 product_layout = '2';
				}

				var show_category = '';
				if(this.state.category_icons_yes === true){
					show_category = 'yes';
				}else{
					show_category = 'no';
				}

				// var maintab_enable_value = '';
				// if(this.state.maintab_enable_yes === true){
				// 	maintab_enable_value = 'yes';
				// }else{
				// 	maintab_enable_value = 'no';
				// }

				var postObject = {
					settings_id: formPayload.settings_id,
					settings_site_title:formPayload.settings_site_title,
					settings_from_name:formPayload.settings_from_name,
					settings_admin_email:formPayload.settings_admin_email,
					settings_from_email:formPayload.settings_from_email,
					settings_mailpath:formPayload.settings_mailpath,
					settings_company_address:formPayload.settings_company_address,
					settings_commission_percentage:formPayload.settings_commission_percentage,
					site_background: formPayload.hexColor,
					smtp_host:formPayload.settings_smtp_host,
					smtp_user:formPayload.settings_smtp_user,
					smtp_pass:formPayload.settings_smtp_pass,
					smtp_port:formPayload.settings_smtp_port,
					admin_user:formPayload.settings_username,
					admin_no:formPayload.settings_mobileno,
					settings_email_footer:formPayload.settings_email_footer,
					settings_mail_from_smtp:settings_mail_from_smtp,
					settings_public_key:formPayload.settings_public_key,
					settings_product_key:formPayload.settings_product_key,
					setting_goal_amount:formPayload.setting_goal_amount,
					supporter_additional_fee:formPayload.supporter_additional_fee,
					settings_secret_key:formPayload.settings_secret_key,
					primary_font:formPayload.primary_font,
					main_tab_font_size:formPayload.main_tab_font_size,
					menu_font_size:formPayload.menu_font_size,
					settings_enable_live:settings_enable_live,
					st_position_left:formPayload.st_position_left,
					st_position_right:formPayload.st_position_right,
					st_position_top:formPayload.st_position_top,
					st_position_bottom:formPayload.st_position_bottom,
					font 		  : fonttype,
					settings_commision_amount: formPayload.settings_commision_amount,
					merchant_commision_to_broker: formPayload.merchant_commision_to_broker,		
					lender_purchase_count: formPayload.lender_purchase_count,								
					plugin_position: plugin_position,
					google_analystic_code: formPayload.google_analystic_code,
					product_layout: product_layout,
					removeId: formPayload.removeID,
					show_category_icons: show_category,
					//is_maintab_enable: maintab_enable_value,
					landing_page:formPayload.landing_page,
					header_cat_limit: formPayload.header_cat_limit,
					invite_friends_perc: formPayload.invite_friends_perc,
					np_theme: formPayload.np_theme,
					count_domain: formPayload.count_domain,
					settings_faq_common_model_id:formPayload.selectedfaqmodelcommonval,
					settings_faq_user_model_id:formPayload.selectedfaqmodeluserval,
					settings_faq_guest_model_id:formPayload.selectedfaqmodelguestval,
					settings_faq_lender_model_id:formPayload.selectedfaqmodellenderval,
					settings_faq_company_model_id:formPayload.selectedfaqmodelcompanyval,
					aichat_type:formPayload.aichat_type,
					ai_fine_tune_model_id: formPayload.ai_fine_tune_model_id,
					leads_tax: formPayload.leads_tax,
					leads_purchase_amount: formPayload.leads_purchase_amount,
					lender_invite_desc: formPayload.lender_invite_desc,
					investor_invite_desc: formPayload.investor_invite_desc,
					old_lender_invite_img: formPayload.old_lender_invite_img,
					old_investor_invite_img: formPayload.old_investor_invite_img,
					pluginwidth: formPayload.pluginwidth_value,
					chat_api_key : formPayload.chat_api_key ,
					terms_and_condition:(localStorage.getItem('terms_and_condition'))? localStorage.getItem('terms_and_condition') : formPayload.terms_and_condition ,
					st_varient_id:formPayload.st_varient_id,
					
				};
	
				let formData = new FormData();
				for(let k in postObject) {
				formData.append(k, postObject[k]);
				}
			/* 	var domains = formPayload.domainSets
				for(let i in domains){
					formData.append('domainName[]',domains[i]['domain_name'])
				} */
				
				var lender_icondata = formPayload.lender_icon;
				formData.append('lender_invite_file',lender_icondata)
				var investor_icondata = formPayload.investor_icon;
				formData.append('investor_invite_file',investor_icondata)

				var domains = formPayload.domainSets;

				for(let i in domains){

					if(domains[i]['domain_id'] ==  'undefined'){
						formData.append('domainName['+ i + '][domain_id]','null')		
					}else if(domains[i]['domain_id'] !==  undefined && domains[i]['domain_id'] !==  ''){
						formData.append('domainName['+ i + '][domain_id]',domains[i]['domain_id'])
					}

					formData.append('domainName['+ i + '][domain_name]',domains[i]['domain_name'])
					formData.getAll('domainName');
				}

				axios.post(apiUrl+"adminpanel/settingsave",formData,config).then(res => {
	
					if(res.data.results){
						this.setState({Loading:false});
					        	const formpayload = res.data.results;
								$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
								this.setState({settings_id:formpayload.settings_id});
								this.setState({terms_and_condition:formpayload.terms_and_condition});
								this.setState({settings_site_title:formpayload.settings_site_title});
								this.setState({settings_from_name:formpayload.settings_from_name});
								this.setState({settings_admin_email:formpayload.settings_admin_email});
								this.setState({settings_from_email:formpayload.settings_from_email});
								this.setState({settings_mailpath:formpayload.settings_mailpath});
								this.setState({settings_company_address:formpayload.settings_company_address});
								this.setState({settings_commission_percentage:formpayload.settings_commission_percentage});
								this.setState({settings_smtp_host:formpayload.settings_smtp_host});
								this.setState({settings_smtp_user:formpayload.settings_smtp_user});
								this.setState({settings_smtp_pass:formpayload.settings_smtp_pass});
								this.setState({settings_smtp_port:formpayload.settings_smtp_port});
								this.setState({settings_username:formpayload.settings_username});
								this.setState({settings_mobileno:formpayload.settings_mobileno});
								this.setState({settings_email_footer:formpayload.settings_email_footer});
								this.setState({hexColor:formpayload.settings_site_background_color});
								this.setState({settings_public_key:formpayload.settings_public_key});
								this.setState({settings_product_key:formpayload.settings_product_key});
								this.setState({setting_goal_amount:formpayload.setting_goal_amount});
								this.setState({supporter_additional_fee:formpayload.supporter_additional_fee});
								this.setState({settings_secret_key:formpayload.settings_secret_key});
								this.setState({primary_font:formpayload.primary_font});
								this.setState({main_tab_font_size:formpayload.main_tab_font_size});
								this.setState({menu_font_size:formpayload.menu_font_size});
								this.setState({settings_embedcode:formpayload.settings_embedcode});
								this.setState({settings_access_code:formpayload.access_code});
								// this.setState({count_domain:formpayload.count_domain});				
								this.setState({st_position_left:formpayload.st_position_left});
								this.setState({st_position_right:formpayload.st_position_right});
								this.setState({st_position_top:formpayload.st_position_top});
								
								this.setState({st_position_bottom:formpayload.st_position_bottom});
								this.setState({domainSets: formpayload.domainSets});
								this.setState({settings_commision_amount: formpayload.settings_commision_amount});
								this.setState({merchant_commision_to_broker: formpayload.merchant_commision_to_broker});	
								this.setState({lender_purchase_count: formpayload.lender_purchase_count});																
								this.setState({header_cat_limit: formpayload.header_cat_limit});
								this.setState({leads_tax: formpayload.leads_tax});
								this.setState({leads_purchase_amount: formpayload.leads_purchase_amount});
								this.setState({lender_invite_desc: formpayload.lender_invitation_desc});
								this.setState({investor_invite_desc: formpayload.investor_invitation_desc});
								this.setState({chat_api_key: formpayload.chat_api_key});
								
								this.setState({landing_page:formpayload.landing_page});

								if(formpayload.lender_invitation_template !== null){
									this.setState({old_lender_invite_img:formpayload.lender_invitation_template});
								}
								if(formpayload.investor_invitation_template !== null){
									this.setState({old_investor_invite_img:formpayload.investor_invitation_template});
								}
								
								if(formpayload.settings_faq_common_model_id!== 'null' && formpayload.settings_faq_common_model_id !== ''){
									this.setState({selectedfaqmodelcommon: {value:formpayload.settings_faq_common_model_id, label:formpayload.settings_faq_common_model_id},selectedfaqmodelcommonval:formpayload.settings_faq_common_model_id });
								}
								
								if(formpayload.settings_faq_user_model_id!== 'null' && formpayload.settings_faq_user_model_id !== ''){
									this.setState({selectedfaqmodeluser: {value:formpayload.settings_faq_user_model_id, label:formpayload.settings_faq_user_model_id},selectedfaqmodeluserval:formpayload.settings_faq_user_model_id });
								}
								if(formpayload.settings_faq_guest_model_id!== 'null' && formpayload.settings_faq_guest_model_id !== ''){
									this.setState({selectedfaqmodelguest: {value:formpayload.settings_faq_guest_model_id, label:formpayload.settings_faq_guest_model_id},selectedfaqmodelguestval:formpayload.settings_faq_guest_model_id});
								}
								if(formpayload.settings_faq_doctor_model_id!== 'null' && formpayload.settings_faq_doctor_model_id !== ''){
									this.setState({selectedfaqmodelbroker: {value:formpayload.settings_faq_doctor_model_id, label:formpayload.settings_faq_doctor_model_id},selectedfaqmodelbrokerval:formpayload.settings_faq_doctor_model_id});
								}
								if(formpayload.settings_faq_company_model_id!== 'null' && formpayload.settings_faq_company_model_id !== ''){
									this.setState({selectedfaqmodelcompany: {value:formpayload.settings_faq_company_model_id, label:formpayload.settings_faq_company_model_id},selectedfaqmodelcompanyval:formpayload.settings_faq_company_model_id });
								}
								if(formpayload.settings_faq_technician_model_id!== 'null' && formpayload.settings_faq_technician_model_id !== ''){
									this.setState({selectedfaqmodellender: {value:formpayload.settings_faq_technician_model_id, label:formpayload.settings_faq_technician_model_id},selectedfaqmodellenderval:formpayload.settings_faq_technician_model_id });
								}
								this.setState({aichat_type: formpayload.ai_model_type});
								this.setState({ai_fine_tune_model_id: formpayload.ai_fine_tune_model_id});

								if( formpayload.google_analystic_code !== 'null'){
									this.setState({google_analystic_code: formpayload.google_analystic_code});
								}else{
									this.setState({google_analystic_code: ''});
								}

								if(formpayload.settings_mail_from_smtp === '1'){
									this.setState({smtp_checked: true});
								}else{
									this.setState({smtp_checked: false});
								}


								if(formpayload.st_plugin_position === 'fixed'){
									this.setState({st_position_checked: true });
								}else{
									this.setState({st_position_checked: false});
								}

								if(formpayload.product_layout === '2'){
									this.setState({product_layout2_checked: true });
									this.setState({product_layout3_checked: false});
								}else{
									this.setState({product_layout3_checked: true});
									this.setState({product_layout2_checked: false });
								}

								if(formpayload.enable_live === '1'){
									this.setState({stripe_checked: true});
								}else{
									this.setState({stripe_checked: false});
								}

								if(formpayload.show_category_icons === 'yes'){
									this.setState({category_icons_yes: true });
									this.setState({category_icons_no: false});
								}else{
									this.setState({category_icons_no: true});
									this.setState({category_icons_yes: false });
								}

								// if(formpayload.is_maintab_enable === 'yes'){
								// 	this.setState({maintab_enable_yes: true });
								// 	this.setState({maintab_enable_no: false});
								// }else{
								// 	this.setState({maintab_enable_yes: false});
								// 	this.setState({maintab_enable_no: true });
								// }


								if(formpayload.pluginwidth === '600'){
									
									this.setState({selectedOption:{value: '600', label: '600px'}});
								}else{
									
									this.setState({selectedOption:{value: '630', label: '630px'}});
					
								}
								scrollToTop();

								setTimeout(
									function() {
										$('.success_message').html('');
									}
									.bind(this),
									3000
									);
				    }
						 
				 }); 
			}
	}

	validateForm() {

		const {settings_commision_amount,merchant_commision_to_broker,lender_purchase_count,settings_id,settings_site_title,settings_from_name,settings_admin_email,settings_from_email,settings_company_address,settings_commission_percentage,settings_smtp_host,settings_smtp_user,settings_smtp_pass,settings_smtp_port,settings_username,settings_mobileno,settings_email_footer,settings_public_key,settings_product_key,setting_goal_amount,supporter_additional_fee,settings_secret_key,settings_embedcode,selectedFont,invite_friends_perc,domainSets,module_type,admin_id,leads_tax,leads_purchase_amount, primary_font, main_tab_font_size, menu_font_size} = this.state;
		let errors = 0;
      	//let formIsValid = true;
		  
		  if (!domainSets.length>0) { 
			
			errors++;
			$('.errorsettings_domain').html('<span class="errorspan">Please add atleast one domain</span>');
		}else{		
			$('.errorsettings_domain').html('');
		}
		if (!settings_site_title) { 

			errors++;
			$('.errorsettings_site_title').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_site_title){		
			$('.errorsettings_site_title').html('');
		}
		
		if (!settings_from_name) { 
			errors++;
			$('.errorsettings_from_name').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_name){
			$('.errorsettings_from_name').html('');
		}

		if (!settings_admin_email) {
			errors++; 
			$('.errorsettings_admin_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_admin_email){
			$('.errorsettings_admin_email').html('');
		}
		if (!settings_from_email) {
			errors++; 
			$('.errorsettings_from_email').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_from_email){
			$('.errorsettings_from_email').html('');
		}
		

		if (!settings_mobileno) { 
			errors++;
			$('.errorsettings_mobileno').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_mobileno){
			$('.errorsettings_mobileno').html('');
		} 


		if (!leads_tax) { 
			errors++;
			$('.errorleads_tax').html('<span class="errorspan">Please fill the field</span>');
		}else if(leads_tax){
			$('.errorleads_tax').html('');
	
			if (leads_tax < 1 || leads_tax > 100) {
				errors++;
	//console.log(leads_tax,"leads_taxleads_taxleads_tax")
				$('.errorleads_tax').html('<span class="errorspan">Percentage must be greater than or equal to 1 and less than or equal to 100</span>');
				// $('.errorleads_tax').html('<span class="errorspan">percentage must be greater than 1 and less than or equal to 100</span>');
	
			}else if(/^[0-9]*\.?[0-9]*$/.test(leads_tax)){
				$('.errorleads_tax').html('');
			  }else{
				errors++;
			$('.errorleads_tax').html('<span class="errorspan">Please enter the valid tax</span>');
			  }
			}
	
		


		
		if (!leads_purchase_amount) { 
			errors++;
			$('.errorleads_purchase_amount').html('<span class="errorspan">Please fill the field</span>');
		}else if(leads_purchase_amount){
			$('.errorleads_purchase_amount').html('');
	
			if (leads_purchase_amount.length  > 6) {
				errors++;
				$('.errorleads_purchase_amount').html('<span class="errorspan">Purchase amount must be 10 digits </span>');
				// $('.errorleads_tax').html('<span class="errorspan">percentage must be greater than 1 and less than or equal to 100</span>');
	
			}else if(/^[0-9]*\.?[0-9]*$/.test(leads_purchase_amount)){
				$('.errorleads_purchase_amount').html('');
			  }else{
				errors++;
			$('.errorleads_purchase_amount').html('<span class="errorspan">Please enter the valid amount</span>');
			  }
			}
	

		if(admin_id == 1 && module_type ==='withlenders'){
		
		if (!merchant_commision_to_broker) { 
			errors++;
			$('.errormerchant_commision_to_broker').html('<span class="errorspan">Please fill the field</span>');
		}else if(merchant_commision_to_broker){
			$('.errormerchant_commision_to_broker').html('');
		}

		if (!lender_purchase_count) { 
			errors++;
			$('.errorlender_purchase_count').html('<span class="errorspan">Please fill the field</span>');
		}else if(lender_purchase_count){
			$('.errorlender_purchase_count').html('');
		}
	}
		if (!settings_email_footer) { 
			errors++;
			$('.errorsettings_email_footer').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_email_footer){
			$('.errorsettings_email_footer').html('');
		}

		
		if(admin_id == 1 && module_type ==='withlenders'){
		if (!settings_public_key) {
			errors++;
			$('.errorsettings_public_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_public_key){
			$('.errorsettings_public_key').html('');
		}

		if (!settings_product_key) {
			errors++;
			$('.errorsettings_product_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_product_key){
			$('.errorsettings_product_key').html('');
		}
		
		if (!settings_secret_key) {
			errors++;
			$('.errorsettings_secret_key').html('<span class="errorspan">Please fill the field</span>');
		}else if(settings_secret_key){
			$('.errorsettings_secret_key').html('');
		}
		if (!primary_font) {
			errors++;
			$('.errorprimary_font').html('<span class="errorspan">Please fill the field</span>');
		}else if(primary_font){
			$('.errorprimary_font').html('');
		}
		if (!main_tab_font_size) {
			errors++;
			$('.errormain_tab_font_size').html('<span class="errorspan">Please fill the field</span>');
		}else if(main_tab_font_size){
			$('.errormain_tab_font_size').html('');
		}
		if (!menu_font_size) {
			errors++;
			$('.errormenu_font_size').html('<span class="errorspan">Please fill the field</span>');
		}else if(menu_font_size){
			$('.errormenu_font_size').html('');
		}
	
		if (!invite_friends_perc) {
			errors++;
			$('.errorinvite_friends_perc').html('<span class="errorspan">Please fill the field</span>');
		}else if(invite_friends_perc){
			//console.log(invite_friends_perc)
			if(invite_friends_perc == 0){
				errors++;
				$('.errorinvite_friends_perc').html('<span class="errorspan"> Please give atleast 1%</span>');
			}else{
				$('.errorinvite_friends_perc').html('');
			}
			
		}
	}
	//  console.log(errors,"errorserrorserrors")
		if(errors>0) {
		setTimeout(function () {
		scrollToTopValidate();
		}, 100);
		return false;
		}
		else {
		return true;
		}
    }

    componentWillReceiveProps(Props){

   

  

	}

	setselectedvalue(selectsers){
		
	    const fontArray = selectsers.map((fonttype, index) => ({
	     id: index,
	     name: fonttype,
	    }));
    // Dynamically create select list
    let fonts = [];
    fontArray.map(item =>
    fonts.push({ label: item.name.label, value: item.name.value }),
    );
    this.setState({selectedFont : fonts})
  }
  	setselectedvariants(selectvarients=null){
		
		selectvarients = (selectvarients != null ? selectvarients : {});
		if (Object.keys(selectvarients).length > 0) {	
		var listings = selectvarients.map((item, index) => {
				//fonts.push({ variant_id: item.name.id, variant_name: item.name.varient_name }),
				//console.log(item.id, '--isChecked')
				if(item.isChecked == 'checked'){
					this.state.st_varient_id.push(item.id);
				}
				//this.setState({ st_varient_id: [...this.state.st_varient_id, item.id] });
				return (						
					<div className="form-group" key={index}>
						<input 
						onChange={this.handleCheckBoxChange.bind(this)}
						type="checkbox"  name="st_varients" 
						value={item.id}
						defaultChecked={item.isChecked}
						/>
						<span>{item.varient_name+' '+item.variant_weight}</span>
					</div>									
				);				
			});
		} else{
			var listings = '';
			this.setState({st_varient_id : []})
		}
		this.setState({selectedFontVariant : listings})
	}

	handleCheckBoxChange = (e) => {
		const isChecked = e.target.checked;
		const isCheckedVal = e.target.value;
		
		if(isChecked){
			this.setState({ st_varient_id: [...this.state.st_varient_id, e.target.value] });
		} else{
			// setSelectedId(selectedIds.filter(id=>id !== checkedId))
			// const index = this.state.st_varient_id.indexOf(e.target.value);
			// this.state.st_varient_id.splice(index,1);
			this.setState({ st_varient_id: this.state.st_varient_id.filter(id=>id !== e.target.value) });
		}
	};

	handleClick = () => {
	this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
	this.setState({ displayColorPicker: false })
	};

	handleChange = (color) => {
	this.setState({hexColor : color.hex})
	this.setState({ color: color.rgb })
	};

	 copyCodeToClipboard(event){
		event.preventDefault;
		const el = this.textArea
		el.select()
		document.execCommand("copy")
   }


   writeText = () => {
	 
	$('.copied_title').html('<span class=""><h3>Copied</h3></span>');

	//onClick={() =>  navigator.clipboard.writeText(tester)}
	let test1 = this.state.settings_access_code;
	
	let  tester =	'<script src="https://admin-f.uxt.design/plugin.js"></script> <div data-client="'+test1+'"  id="formbooking_container"></div>';
	 navigator.clipboard.writeText(tester);
	setTimeout(
		function() {
			$('.copied_title').html('');
			$('.copied_title').hide();
		}
		.bind(this),
		3000
		);
		
    }

/*    myCallback = (domainsets) => {
   	//console.log(domainsets,'domainsets')
    this.setState({domainSets: domainsets});
  }; */

  myCallback = (domainsets,domainID) => {

    this.setState({domainSets: domainsets,removeID: domainID});
   };


   handleChangeFont = selectedFont => {
      this.setState({ selectedFont});
      this.setState({ fontvalue : selectedFont.value });
	  if(selectedFont.value === ''){
        //$('.errorfont').html('<span class="errorspan">Please select the font</span>');
      }else{
		axios.get(apiUrl+'adminpanel/getfont_varient?font_id='+selectedFont.value)
			.then(res => {
				if(res.data.status == 'success'){					
					//if(res.data.fontvarientlist!== '' && res.data.fontvarientlist!== null){
						this.setselectedvariants(res.data.fontvarientlist);
					//}
				
				}else{
				//console.log("test")
					this.setselectedvariants(res.data.fontvarientlist);
				}
			});
      	 $('.errorfont').html('');
      }
   }


   	isNumber(e) {
		var code = (e.which) ? e.which : e.keyCode;
		if (code > 31 && (code < 48 || code > 57)) {
			e.preventDefault();
		}
	}

	handleChangeAimodelcommon = selectedfaqmodelcommon => {
		this.setState({ selectedfaqmodelcommon});
		this.setState({ selectedfaqmodelcommonval : selectedfaqmodelcommon.value });	
		}

   handleChangeAimodeluser = selectedfaqmodeluser => {
	this.setState({ selectedfaqmodeluser});
	this.setState({ selectedfaqmodeluserval : selectedfaqmodeluser.value });	
	}
	handleChangeAimodelguest = selectedfaqmodelguest => {
		this.setState({ selectedfaqmodelguest});
		this.setState({ selectedfaqmodelguestval : selectedfaqmodelguest.value });	
	}
	handleChangeAimodelbroker = selectedfaqmodelbroker => {
		this.setState({ selectedfaqmodelbroker});
		this.setState({ selectedfaqmodelbrokerval : selectedfaqmodelbroker.value });	
	}

	handleChangeAimodelcompany = selectedfaqmodelcompany => {
		this.setState({ selectedfaqmodelcompany});
		this.setState({ selectedfaqmodelcompanyval : selectedfaqmodelcompany.value });	
	}
	handleChangeAimodellender = selectedfaqmodellender => {
		this.setState({ selectedfaqmodellender});
		this.setState({ selectedfaqmodellenderval : selectedfaqmodellender.value });	
	}
	handleChangeWidth = selectedOption => {
        this.setState({  selectedOption : selectedOption});
        this.setState({  pluginwidth_value : selectedOption.value});
    };
	onEditorChange( evt ) {
    	// console.log(evt.editor.getData())
    	// var callfunc = this;
    	// setTimeout(function () {
		// callfunc.setState( {
        //     terms_and_condition: evt.editor.getData()
        // } );
		// }, 1000);
		localStorage.setItem('terms_and_condition',evt.editor.getData())
     
	}
	
	onChangeHandler=(action, event)=>{
		//console.log(action,"Setting.js");
		if(action == 'lender'){
			let reader = new FileReader();
			const file = event.target.files[0];
			const extension = file.name.split('.').pop();
			reader.onloadend = () => {
			  this.setState({
				lender_icon_name: file.name,
				lender_icon_preview: reader.result,
				lender_icon: file,
				lender_icon_fileExtension:extension,
				
			  });
			};
			reader.readAsDataURL(file);
		} else if(action == 'investor'){
			let reader = new FileReader();
			const file = event.target.files[0];
			const extension = file.name.split('.').pop();
			reader.onloadend = () => {
			  this.setState({
				investor_icon_name: file.name,
				investor_icon_preview: reader.result,
				investor_icon: file,
				investor_icon_fileExtension:extension,
				
			  });
			};
			reader.readAsDataURL(file);
		}
	  } 

	  handleSave = () => {
			this.setState({Loading:true});
			const config = {
				headers: {
				  'Content-Type': 'multipart/form-data'
				}
			  };
			const formPayload = this.state;
			var qs = require('qs');
			var postObject = {
				
				settings_id: formPayload.settings_id,
				admin_id: localStorage.getItem('admin_id'),
				aifeed:this.state.description,
				type : formPayload.type,
				imp_cate : 6
				
			};

			let formData = new FormData();
			for(let k in postObject) {
			formData.append(k, postObject[k]);
			}
		
			
			

			axios.post(apiUrl+"faq/settingaifeedsave",formData,config).then(res => {
				if(res.data.results){
					// this.setState({Loading:false});
					// 		const formpayload = res.data.results;
					// 		$('.success_message').html('<div class="status_sucess"><h3>'+ res.data.message+'</h3></div>');
					// 		this.setState({settings_id:formpayload.settings_id});
					// 		scrollToTop();
					// 		setTimeout(
					// 			function() {
					// 				$('.success_message').html('');
					// 			}
					// 			.bind(this),
					// 			3000
					// 			);
				}
					 
			 }); 
		
	}
	  
  render() {
  	const styles = reactCSS({
      'default': {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${ this.state.hexColor }`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });
	let test1 = this.state.settings_access_code;
	//let test = Number(this.state.settings_access_code);
	
	const scripttype = '';
	
		
	let  tester =	'<script src="https://a-fund.uxt.design/plugin.js"></script> <div data-client="'+test1+'"  id="fund_container"></div>';


	const fontArray = this.state.fonttype.map ((fonttype, index) => ({
	id: index,
	name: fonttype,
	}));
	// Dynamically create select list
	let fonts = [];
	fontArray.map(item =>
	fonts.push({ label: item.name.label, value: item.name.value }),
	);

	const {selectedOption,selectedFont,selectedfaqmodeluser,selectedfaqmodelguest,selectedfaqmodelcommon ,selectedfaqmodelbroker,selectedfaqmodellender,selectedfaqmodelcompany} = this.state;
	//console.log(selectedOption,"selectedOptionselectedOptionselectedOption")

	const style_text = {
	color: 'white',
    backgroundColor: '#3E3E3C',
    padding: '9px 9px',
    minwidth: '49px',
    height: '37px',
	textalign:'center',
    texttransform: 'capitalize'
}
	

		var	lender_preview = (this.state.lender_icon_preview) ? 
		<img className="img_class" src={this.state.lender_icon_preview} alt="" /> :
		<img className="img_class" src={mediaUrl+'settings/'+this.state.old_lender_invite_img} alt="" />;
		var	investor_preview = (this.state.investor_icon_preview) ? 
		<img className="img_class" src={this.state.investor_icon_preview} alt="" /> :
		<img className="img_class" src={mediaUrl+'settings/'+this.state.old_investor_invite_img} alt="" />;

		//console.log(this.state_lender_invite_desc,"this.state_lender_invite_desc");
    return (
      <div className="wrapper"> 
 
	  <Header />
	  <MenuSidebar currentpage="setting" />		

	
	<div className="content">	
		<div className="content-wrapper">
		  <div class="content-wrapper-before"></div>
			<div className="content-body setting-main-head leaa-admin-head card">
			<form className="settings" onSubmit={ e => { this.handleFormSubmit(this); e.preventDefault(); }} encType="multipart/form-data">
			<div className="form-wrapper">
			<div className="success_message"></div>
			<div className="title">
				<h4>Settings</h4>
			</div>
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
				{localStorage.getItem('admin_id') == 1 ?<label>Site Name<span class="required" style={{ color: "red" }} > * </span></label>:<label>Company Name<span class="required" style={{ color: "red" }} > * </span></label>}
					
					<input type="text" name="settings_site_title" onChange={this.handleInputChange} className="form-control" value={this.state.settings_site_title} />
					<div className="errorsettings_site_title"></div>
				</div>
				<div className="form-group">					
					<label>From Name<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" name="settings_from_name" onChange={this.handleInputChange} className="form-control"value={this.state.settings_from_name} />
					<div className="errorsettings_from_name"></div>
				</div>
				<div className="form-group">
					<label>From Email<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" name="settings_from_email"  onChange={this.handleInputChange} className="form-control" value={this.state.settings_from_email} />
					<div className="errorsettings_from_email"></div>
				</div>
			
				

				
			
			</div>
			<div className="form-right">

				<div className="form-group">					
					{localStorage.getItem('admin_id') == 1 ? <label>Admin Email<span class="required" style={{ color: "red" }} > * </span></label>:<label>Company Email<span class="required" style={{ color: "red" }} > * </span></label>} 
					<input type="text" className="form-control" onChange={this.handleInputChange} name="settings_admin_email" value={this.state.settings_admin_email} />
					<div className="errorsettings_admin_email"></div>
				</div>
				
				<div className="form-group">
					<label>Contact<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control" name="settings_mobileno"  onChange={this.handleInputChange} value={this.state.settings_mobileno}/>
					<div className="errorsettings_mobileno"></div>
				</div>

				<div className="form-group"> 
					<label>Email Footer Content<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control" name="settings_email_footer" onChange={this.handleInputChange} value={this.state.settings_email_footer}/>
					<div className="errorsettings_email_footer"></div>
				</div>

				{/* localStorage.getItem('admin_id') == 1 ? <div className="form-group site-bg-clr"> 
				<label>Site Background Color: </label>
						<div style={ styles.swatch } onClick={ this.handleClick }>
						<div style={ styles.color } />
						</div>
						{ this.state.displayColorPicker ? <div style={ styles.popover }>
						<div style={ styles.cover } onClick={ this.handleClose }/>
						<SketchPicker color={ this.state.color } onChange={ this.handleChange } />
						</div> : null }
				</div> : '' */}

				  
			</div>				
			</div>	

		    <div className="title">
				<h4>Mail Configuration Settings</h4>
			</div> 
			
			 <div className="form-group">
				    <input onChange={this.handleInputChange} type="checkbox"  name="settings_mail_from_smtp" checked={this.state.smtp_checked}/>
	                <span>Send Mail From Smtp</span>
            </div> 

		 <div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Smtp Host:</label>
					<input type="text" className="form-control" name="settings_smtp_host" onChange={this.handleInputChange}  value={(this.state.settings_smtp_host != 'null')?this.state.settings_smtp_host:''}/>
					<div className="errorsettings_smtp_host"></div>
				</div>
				<div className="form-group">					
					<label>Smtp Username:</label>
					<input type="text" className="form-control" name="settings_smtp_user"  onChange={this.handleInputChange}  value={(this.state.settings_smtp_user != 'null')?this.state.settings_smtp_user:''}  autoComplete='false' />
					<div className="errorsettings_smtp_user"></div>
				</div>					
				<div className="form-group">
					<label>Smtp Password:</label>
					<input type="password" className="form-control" name="settings_smtp_pass" onChange={this.handleInputChange}  value={(this.state.settings_smtp_pass != 'null')?this.state.settings_smtp_pass:''} autoComplete='false' />
				<div className="errorsettings_smtp_pass"></div>
				</div>
			</div>			
			<div className="form-right">
				<div className="form-group">					
					<label>Smtp Port:</label>
					<input type="text" className="form-control"  name="settings_smtp_port" onChange={this.handleInputChange}  value={(this.state.settings_smtp_port != 'null')?this.state.settings_smtp_port:''} />
				<div className="errorsettings_smtp_port"></div>
				</div>
				<div className="form-group"> 
					<label>Mail Path:</label>
					<input type="text" className="form-control" name="settings_mailpath" onChange={this.handleInputChange} placeholder=""  value={(this.state.settings_mailpath != 'null')?this.state.settings_mailpath:''}/>
				</div>
			</div>	
			</div> 
			
			{/* <div className="title">
				<h4>Commision Settings</h4>
			</div>  

			
			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Commision Percentage %:</label>
					<input type="text" className="form-control" name="settings_commision_amount" onChange={this.handleInputChange}  value={(this.state.settings_commision_amount != 'null')?this.state.settings_commision_amount:''}/>
					<div className="errorsettings_commision_amount"></div>
				</div>
				</div>
				</div>  */}


				

{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="title">
				<h4>Lead Settings</h4>
			</div>:null}

			
			{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Merchant Commision To Broker %<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="number" pattern="[0-9.*]" maxlength="5" className="form-control num_valid" name="merchant_commision_to_broker" onChange={this.handleInputChange}  value={(this.state.merchant_commision_to_broker != 'null')?this.state.merchant_commision_to_broker:''}/>
					<div className="errormerchant_commision_to_broker"></div>
				</div>
				<div className="form-group">
					<label>Tax %<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text"  className="form-control num_valid" name="leads_tax" onChange={this.handleInputChange}  value={(this.state.leads_tax != 'null')?this.state.leads_tax:''}/>
					<div className="errorleads_tax"></div>
				</div>
				</div>

				<div className="form-right">
				<div className="form-group">
					<label>Individual Lead Purchase Limit<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="number" pattern="[0-9.*]" maxlength="3" className="form-control num_valid" name="lender_purchase_count" onChange={this.handleInputChange}  value={(this.state.leads_purchase_amount != 'null')?this.state.lender_purchase_count:''}/>
					<div className="errorlender_purchase_count"></div>
				</div>
				<div className="form-group">
					<label>Leads Purchase Amount $<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control num_valid" name="leads_purchase_amount" onChange={this.handleInputChange}  value={(this.state.leads_purchase_amount != 'null')?this.state.leads_purchase_amount:''}/>
					<div className="errorleads_purchase_amount"></div>
				</div>
				</div>
				</div>:null}

		{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="title">
			<h4>Invite Settings</h4>
		</div>:''}

			{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Lender Invitation Description</label>					
					{/* <input type="text"  className="form-control " name="lender_invite_desc" onChange={this.handleInputChange} value={(this.state.lender_invite_desc != null)?this.state.lender_invite_desc:''} /> */}

					<textarea  className="form-control " name="lender_invite_desc" onChange={this.handleInputChange} value={(this.state.lender_invite_desc != null)?this.state.lender_invite_desc:''} ></textarea>
					<div className="errorlender_invite_desc"></div>
				</div>
				<div className="form-group">
					<label>Lender Invitation Template</label>
					<div className="choose-file">
						{this.state.isHidden && <div className="image_success"></div>}
						<span className="profile_btn"><input type="file" name="lender_invite_file" onChange={this.onChangeHandler.bind(this,'lender')} /></span>
					</div>
					{lender_preview}
					<div className="errorlender_invite_template"></div>
				</div>
				</div>

				<div className="form-right">
				
				<div className="form-group">
					<label>Investor Invitation Description</label>
					<textarea  className="form-control " name="investor_invite_desc" onChange={this.handleInputChange} value={(this.state.investor_invite_desc != 'null')?this.state.investor_invite_desc:''} ></textarea>
					<div className="errorinvestor_invite_desc"></div>
				</div>
				<div className="form-group">
					<label>Investor Invitation Template</label>
						<div className="choose-file">
							{this.state.isHidden && <div className="image_success"></div>}
							<span className="profile_btn"><input type="file" name="investor_invite_file" onChange={this.onChangeHandler.bind(this,'investor')} /></span>
						</div>
						{investor_preview}
					<div className="errorinvestor_invite_template"></div>
				</div>
				</div>
				</div>: ''}

				{/* -------------chat ai setting start-------------- */}

			<div className="title">
				<h4>Chat AI Setting</h4>
		   </div> 

			<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Chat AI type: </label>					
				</div>

				<div className="form-group">
					<label>Chat API key</label>					
				</div>

				<div className="form-group">
					<label>Chat Model: </label>					
				</div>

				<div className="form-group">
					<label>Common /Guest Conversation</label>					
				</div>

				<div className="form-group">
					<label>User Conversation</label>					
				</div>

				{/* <div className="form-group">
					<label>Broker Conversation</label>					
				</div> */}
				
				{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="form-group">
					<label>Lender Conversation</label>					
				</div>:null}
				<div className="form-group">
					<label>Company Conversation</label>					
				</div>
				
			</div> 
			<div className="form-right">
			<div className="form-group">						
				<input onChange={this.handleInputChange} type="radio" name="aichat_type" checked={this.state.aichat_type=='text'?'chekced':''} value="text"/>
				<span>Text</span>
					<input onChange={this.handleInputChange} type="radio"  name="aichat_type" checked={this.state.aichat_type=='chat'?'chekced':''} value="chat"/>
				<span>Chat </span>
			</div> 

			<div className="form-group">						
				<input onChange={this.handleInputChange}  type="text" name="chat_api_key" value={this.state.chat_api_key}/>
				
			</div> 

			<div className="form-group">					
				
				<Select 
					className="z-index-999"
					options={this.state.faqmodelcommon}  
					value={selectedfaqmodelcommon?selectedfaqmodelcommon:{ value: '0', label: 'Select an ai model' }}
					onChange={this.handleChangeAimodelcommon}
					placeholder="Select AI Model ID" 
				/>			
			</div>

			<div className="form-group">					
				
				<Select 
					options={this.state.faqmodelguest}  
					value={selectedfaqmodelguest?selectedfaqmodelguest:{ value: '0', label: 'Select guest ai model' }}
					onChange={this.handleChangeAimodelguest}
					placeholder="Select AI Model ID" 
				/>			
			</div>

			<div className="form-group">					
				<Select 
					options={this.state.faqmodeluser}  
					value={selectedfaqmodeluser?selectedfaqmodeluser:{ value: '0', label: 'Select user ai model' }}
					onChange={this.handleChangeAimodeluser}
					placeholder="Select AI Model ID" 
				/>			
			</div>			

			{/* <div className="form-group">					
				<Select 
					options={this.state.faqmodelbroker}  
					value={selectedfaqmodelbroker?selectedfaqmodelbroker:{ value: '0', label: 'Select broker ai model' }}
					onChange={this.handleChangeAimodelbroker}
					placeholder="Select AI Model ID" 
				/>			
			</div>	 */}
			{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="form-group">					
				<Select 
					options={this.state.faqmodellender}  
					value={selectedfaqmodellender?selectedfaqmodellender:{ value: '0', label: 'Select lender ai model' }}
					onChange={this.handleChangeAimodellender}
					placeholder="Select AI Model ID" 
				/>			
			</div>:null}
			<div className="form-group">					
				<Select  
					options={this.state.faqmodelcompany}  
					value={selectedfaqmodelcompany?selectedfaqmodelcompany:{ value: '0', label: 'Select company ai model' }}
					onChange={this.handleChangeAimodelcompany}
					placeholder="Select AI Model ID" 
				/>			
			</div>	
			
			
			</div>
      	</div>
		{/* -------------chat ai setting end-------------- */}
		<div className="title">
				<h4>Feed domain data</h4>
		</div>

		<div className="form-group">					
					<label>Type</label>
						   <input type="radio" name='type' value="text" onChange={this.handleInputChange}/> Text 
						   <input type="radio" name='type' value="chat" onChange={this.handleInputChange}/> Chat 
	           </div>  
		
		<div className="form-group">					
						{/* <textarea type="text" name='description' onChange={this.handleInputChange} className='form-control' value={this.state.description} /> */}
						<CKEditor
						data={this.state.description}
						//data={templatecontent}
						onChange={this.onEditorAIChange}
						type="classic"
						/>
					<div className="errordescription"></div>
					<button className='ai-feedbtn' onClick={this.handleSave}>AI Feed</button>
				</div>		
	    
			 

	    {/*AI Teaching******************************* start */}

		  {/*Bank statement settings start */}

		
	    
			   {/*Bank statement settings end */}

		
		<div className="title">
				<h4>Domain & Embed Code</h4>
		</div> 
		<div className="form-row">
				<div className="form-left">
				<div className="form-group">
						<label>Embed Code</label>
						<p className="right-side-header"  id="embed_code" name="settings_embedcode" value={tester} title="Copy Text" >{tester}</p>
						<br></br>
						<a id='copytext' style={style_text} onClick={this.writeText}   >Copy  </a> <br></br>
						<div><br></br><div className="copied_title"></div></div>
						<div className="errorsettings_embedcode_title"></div>
					</div>
					
					<div className='form-group'>
				     <h4>Google Analytics code </h4>
					 <textarea type="text" name="google_analystic_code" onChange={this.handleInputChange} className="form-control" value={this.state.google_analystic_code} />
					</div>
				</div>	
				<div className="form-right">
				    <div className='form-group'>
				     <h4>Domain<span class="required" style={{ color: "red" }} > * </span></h4>
				    <div className="domain-url">
					<Addinputsimple myCallback={this.myCallback} domainname={this.state.domainSets} count_domain={this.state.count_domain}/>
					</div>
					<div className="errorsettings_domain"></div>
					</div>
				</div>				
			</div>
			 <h5>Plugin Position (Numbers Only)</h5> 
			 <div className="form-row">			
				<div className="form-left">
					<div className="form-group">
						<label>Left %</label>
						<input type="number" className="form-control" name="st_position_left"  onChange={this.handleInputChange} value={this.state.st_position_left}/>
						<div className="errorsettings_st_position_left"></div>
					</div>
					<div className="form-group">
						<label>Right %</label>
						<input type="number" className="form-control" name="st_position_right"  onChange={this.handleInputChange} value={this.state.st_position_right}/>
						<div className="errorsettings_st_position_right"></div>
					</div>
					<div className="form-group">
						<label>Position  </label>
						<input onChange={this.handleInputChange} type="checkbox"  name="st_position" checked={this.state.st_position_checked}/>
	                    <span>Fixed</span>
					</div>
					{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?	<div className="form-group">
						<label>Show category icons </label>
					    <input onChange={this.handleInputChange} type="radio" name="category_icons" checked={this.state.category_icons_yes}/>
		                <span>Yes</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="category_icons" checked={this.state.category_icons_no}/>
		                <span>No</span>
 					</div>:null}
					 {/* {this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?	<div className="form-group">
						<label>Enable Maintab</label>
					    <input onChange={this.handleInputChange} type="radio" name="enable_maintab" checked={this.state.maintab_enable_yes}/>
		                <span>Yes</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="enable_maintab" checked={this.state.maintab_enable_no}/>
		                <span>No</span>
 					</div>:null} */}
					 {this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?	<div className="form-group">
						<label>Plugin Landing Page</label><br/>					    
		                 <input onChange={this.handleInputChange} type="radio"  name="landing_page" checked={this.state.landing_page === "MainTab"} value="MainTab"/>
		                <span>Main</span>
						<input onChange={this.handleInputChange} type="radio"  name="landing_page" checked={this.state.landing_page === "Ads"} value="Ads"/>
		                <span>Ads</span>
						<input onChange={this.handleInputChange} type="radio"  name="landing_page" checked={this.state.landing_page === "LeadForm"} value="LeadForm"/>
		                <span>Lead form</span>
						<input onChange={this.handleInputChange} type="radio" name="landing_page" checked={this.state.landing_page === "InnerTab"} value="InnerTab" />
		                <span>Off</span>
 					</div>:null}

					
					
					<div className="form-group">
						<label>Notification Popup Theme </label>
					    <input onChange={this.handleInputChange} type="radio" name="np_theme" value="Black" checked={(this.state.np_theme == "Black" ? "checked" : "" )}/>
		                <span>Black bg with White text</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="np_theme" value="White" checked={(this.state.np_theme == "White" ? "checked" : "" )}/>
		                <span>White bg with Black text</span>
 					</div> 
					 <div className="form-group">					
					<label> Plugin width</label>
						   <Select name="plugin_width"
						   value={selectedOption}
                            options={lang.common.pluginWidth_option} 
                            onChange={this.handleChangeWidth}
                            />
					<div className=""></div>
				</div>
				</div>
				<div className="form-right">
					<div className="form-group">
						<label>Top %</label>
						<input type="number" className="form-control" name="st_position_top"  onChange={this.handleInputChange} value={this.state.st_position_top}/>
						<div className="errorsettings_st_position_top"></div>
					</div>
					<div className="form-group">
						<label>Bottom %</label>
						<input type="number" className="form-control" name="st_position_bottom"  onChange={this.handleInputChange} value={this.state.st_position_bottom}/>
						<div className="errorsettings_st_position_bottom"></div>
					</div>

					{/*<div className="form-group">
						<label>Product Layout: </label>
					    <input onChange={this.handleInputChange} type="radio" name="product_layout" checked={this.state.product_layout2_checked}/>
		                <span>2 Layout</span>
		                 <input onChange={this.handleInputChange} type="radio"  name="product_layout" checked={this.state.product_layout3_checked}/>
		                <span>3 Layout</span>
 					</div>*/} 

{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ? <div className="form-group">
						<label>Header category limit </label>
						<input type="number" className="form-control" name="header_cat_limit"  onChange={this.handleInputChange} value={this.state.header_cat_limit}/>
						{/* <div className="errorsettings_st_position_bottom"></div> */}
					</div>:null}

					{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="form-group">
						<label>Invite Friends %</label>
						<input type="number" className="form-control" name="invite_friends_perc"  onChange={this.handleInputChange} value={this.state.invite_friends_perc}/>
						<div className="errorinvite_friends_perc"></div>
					</div>:null}

				</div>
				<div className="form-group">					
						<label>Terms and Conditions</label>
						<CKEditor
						data={this.state.terms_and_condition}
						//data={templatecontent}
						onChange={this.onEditorChange}
						type="classic"
						/>
						<div className="errorlogo_content"></div>
					</div>
			</div> 
			

			{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?  <div className="title">
				<h4>Stripe Configuration</h4>
		   </div>:null}
		   {this.state.admin_id == 1 && this.state.module_type ==='withlenders' ? <div className="form-group">
				    <input onChange={this.handleInputChange} type="radio" name="settings_enable_live" checked={this.state.stripe_live_checked}/>
	                <span>Live</span>
	                 <input onChange={this.handleInputChange} type="radio"  name="settings_enable_sandbox" checked={this.state.stripe_sandbox_checked}/>
	                <span>SandBox</span>
            </div>:null}
			{this.state.admin_id == 1 && this.state.module_type ==='withlenders' ?<div className="form-row">
			<div className="form-left">
				<div className="form-group">
					<label>Public Key<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control" name="settings_public_key" onChange={this.handleInputChange}  value={this.state.settings_public_key}/>
					<div className="errorsettings_public_key"></div>
				</div>

				<div className="form-group">
					<label>Product Key<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control" name="settings_product_key" onChange={this.handleInputChange}  value={this.state.settings_product_key}/>
					<div className="errorsettings_product_key"></div>
					<span>Note : For Recurring payment(Monthly) the product key use.</span>
				</div>
					
			</div>	
			<div className="form-right">
				
				<div className="form-group">					
					<label>Secret Key<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="password" className="form-control" name="settings_secret_key"  onChange={this.handleInputChange}  value={this.state.settings_secret_key} />
					<div className="errorsettings_secret_key"></div>
				</div>	
			</div>	
      	</div>:null}	

		  <div className="title">
				<h4>Font Management</h4>
			</div> 
			<div className="form-row">		
		  	<div className="form-left">
			  <div className="default-font">
					<div className="form-group">
						<label>Font:</label>
						<Select 
							options={fonts}  
							value={selectedFont?selectedFont:{ value: '0', label: 'Select Font Type' }}
							onChange={this.handleChangeFont}
							placeholder="Select Font Type" />
						<div className="errorfont"></div>
					</div>
					<div className={this.state.selectedFontVariant != '' ? "show-font-type form-group":"hide-font-type form-group"} >
						<label>Font Variant:</label>
						{this.state.selectedFontVariant}
					</div>
				</div> 

			  	<div className="form-group">
					<label>Primary Font Size<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control" name="primary_font" onChange={this.handleInputChange}  value={this.state.primary_font}/>
					<div className="errorprimary_font"></div>
					
				</div>
			</div>

			<div className="form-right">	
				<div className="form-group">
					<label>Main Tab Font Size <span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control" name="main_tab_font_size" onChange={this.handleInputChange}  value={this.state.main_tab_font_size}/>
					<div className="errormain_tab_font_size"></div>
				</div>

				<div className="form-group">
					<label>Menu Font Size<span class="required" style={{ color: "red" }} > * </span></label>
					<input type="text" className="form-control" name="menu_font_size" onChange={this.handleInputChange}  value={this.state.menu_font_size}/>
					<div className="errormenu_font_size"></div>					
				</div>
					
				
			</div>	
			</div>	

			<div className="btn-group export">	
				
                <button className="btn btn_orange btn_minwid login_submit animate-btn2" type="submit" disabled={(this.state.Loading ===true)?true:false}> {this.state.Loading ===true &&
                                <span className="load-data">Loading</span> 
                                } Submit
			    </button>	
			</div>

			</div>
			</form>
			</div>	
		</div>	
	</div>
		 	
    </div>
    );
  }
}

export default (withRouter(Setting));